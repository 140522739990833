/* LandingForm.css */

/* Form section */  

  
  /*BREAKPOINTS*/
  
  /* Mobile Vertical (hasta 480px) */
  @media (max-width: 480px) {
    
    .landing-peru-form-section {
      display: flex;
      flex-direction: column;
      height: auto;
      overflow: hidden;
      padding: 4em; /* Añade padding para más espacio */
      background-image: url(../../../public/images/LandingPeru/banner_horizontal.png);
    }
    
    .landing-peru-form-title {
      font-size: 2em;
      margin-top: 1em;
      text-align: center; /* Centra el título */
    }
    
    .landing-peru-form-content {
      display: flex;
      flex-direction: column;
      align-items: center; /* Asegura que todo esté centrado */
      justify-content: center; /* Distribuye el espacio entre imagen y formulario */
      height: auto;
      width: 100%; /* Asegura que ocupe todo el ancho */
      margin: auto;
      margin-top: 2em;
    }

    .landing-peru-form-image {
      display: none;
    }
    
    .landing-peru-form-image img {
    }
    
    .landing-peru-form {
      width: 100%;
      max-width: 900px; /* Limita el ancho máximo del formulario */
      padding: 1em;
      display: flex;
      flex-direction: column; /* Cambia a una sola columna */
      gap: 1em; /* Espacio entre campos */
      background: white;
      border-radius: 12px; /* Bordes redondeados para un aspecto moderno */
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);

    }
    
    .landing-peru-form-group {
      display: flex;
      flex-direction: column;
      width: 100%; /* Asegura que los grupos ocupen el 100% del ancho de la celda */
    }
    
    .landing-peru-form-group label {
      margin-bottom: 0.5em;
  
    }
    
    .landing-peru-form-group input,
    .landing-peru-form-group textarea {
      width: auto; /* Los campos de input ocupan el 100% del ancho de su celda */
      padding: 0.5em;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
    
    .landing-peru-form-group textarea {
      grid-column: span 2; /* El textarea ocupa dos columnas */
      resize: vertical; /* Permite redimensionar verticalmente */
      width: auto;
    }
    
    .landing-peru-form-submit-button {
      grid-column: span 2; /* El botón ocupa dos columnas */
      padding: 1em 1.5em;
      margin: auto;
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      text-align: center; /* Centra el botón */
    }
    
    .landing-peru-form-submit-button:hover {
      background-color: #0056b3;
    }

  } 
  /* Mobile Horizontal (481px a 768px) */
  @media (min-width: 481px) and (max-width: 768px) {

    .landing-peru-form-section {
      display: flex;
      flex-direction: column;
      height: auto;
      overflow: hidden;
      padding: 4em; /* Añade padding para más espacio */
      background-image: url(../../../public/images/LandingPeru/banner_horizontal.png);
    }
    
    .landing-peru-form-title {
      font-size: 2em;
      margin-top: 0em;
      text-align: center; /* Centra el título */
    }
    
    .landing-peru-form-content {
      display: flex;
      flex-direction: column;
      align-items: center; /* Asegura que todo esté centrado */
      justify-content: center; /* Distribuye el espacio entre imagen y formulario */
      height: auto;
      width: 100%; /* Asegura que ocupe todo el ancho */
      margin: auto;
      margin-top: 2em;
    }
    
    .landing-peru-form-image {
      display: none;
    }
    
    .landing-peru-form {
      width: 100%;
      max-width: 600px; /* Reducí el ancho para mejor alineación */
      padding: 1em;
      display: flex;
      flex-direction: column; /* Todo en una sola columna */
      gap: 1em; /* Espacio entre campos */
      background: white;
      border-radius: 12px; /* Bordes redondeados para un aspecto moderno */
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    }
    
    .landing-peru-form-group {
      display: flex;
      flex-direction: column;
      width: 95%; /* Asegura que los grupos ocupen el 100% del ancho */
    }
    
    .landing-peru-form-group label {
      margin-bottom: 0.5em;
    }
    
    .landing-peru-form-group input,
    .landing-peru-form-group textarea {
      width: 100%; /* Ocupar todo el ancho disponible */
      padding: 0.5em;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
    
    .landing-peru-form-group textarea {
      resize: vertical; /* Permite redimensionar verticalmente */
    }
    
    .landing-peru-form-submit-button {
      width: 40%; /* Define el ancho del botón */
      padding: 1em 0;
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      text-align: center; /* Centra el texto dentro del botón */
      display: block; /* Asegura que respete el margen automático */
      margin: 0 auto; /* Centra el botón horizontalmente */
    }
    
    .landing-peru-form-submit-button:hover {
      background-color: #0056b3;
    }
  
  }
  
  /* Desktop (769px a 1199px) */
  @media (min-width: 769px) and (max-width: 1199px) {
  
    .landing-peru-form-section {
      display: flex;
      flex-direction: column;
      height: 75vh;
      overflow: hidden;
      padding: 4em; /* Añade padding para más espacio */
      background-image: url(../../../public/images/LandingPeru/banner_horizontal.png);
    }
    
    .landing-peru-form-title {
      font-size: 2em;
      margin-top: 0.5em;
      text-align: center; /* Centra el título */
    }
    
    .landing-peru-form-content {
      display: flex;
      flex-direction: column;
      align-items: center; /* Asegura que todo esté centrado */
      justify-content: center; /* Distribuye el espacio entre imagen y formulario */
      height: auto;
      width: 100%; /* Asegura que ocupe todo el ancho */
      margin: auto;
      margin-top: 2em;
    }

    .landing-peru-form-image {
      display: none;
    }
    
    .landing-peru-form-image img {
    }
    
    .landing-peru-form {
      width: 100%;
      max-width: 900px; /* Limita el ancho máximo del formulario */
      padding: 1em;
      display: grid;
      grid-template-columns: repeat(2, 1fr); /* Dos columnas */
      gap: 1em; /* Espacio entre campos */
      justify-items: center; /* Centra los elementos horizontalmente */
      background: white;
      border-radius: 12px; /* Bordes redondeados para un aspecto moderno */
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);

    }
    
    .landing-peru-form-group {
      display: flex;
      flex-direction: column;
      width: 100%; /* Asegura que los grupos ocupen el 100% del ancho de la celda */
    }
    
    .landing-peru-form-group label {
      margin-bottom: 0.5em;
  
    }
    
    .landing-peru-form-group input,
    .landing-peru-form-group textarea {
      width: auto; /* Los campos de input ocupan el 100% del ancho de su celda */
      padding: 0.5em;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
    
    .landing-peru-form-group textarea {
      grid-column: span 2; /* El textarea ocupa dos columnas */
      resize: vertical; /* Permite redimensionar verticalmente */
      width: auto;
    }
    
    .landing-peru-form-submit-button {
      grid-column: span 2; /* El botón ocupa dos columnas */
      padding: 1em 1.5em;
      margin: auto;
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      text-align: center; /* Centra el botón */
    }
    
    .landing-peru-form-submit-button:hover {
      background-color: #0056b3;
    }

  }
  
  /* Large Desktop (1200px en adelante) */
  @media (min-width: 1200px) {

    .landing-peru-form-section {
      display: flex;
      flex-direction: column;
      height: 85vh;
      overflow: hidden;
      padding: 4em; /* Añade padding para más espacio */
      background-image: url(../../../public/images/LandingPeru/banner_horizontal.png);
    }
    
    .landing-peru-form-title {
      font-size: 2em;
      margin-top: 1em;
      text-align: center; /* Centra el título */
    }
    
    .landing-peru-form-content {
      display: flex;
      flex-direction: column;
      align-items: center; /* Asegura que todo esté centrado */
      justify-content: center; /* Distribuye el espacio entre imagen y formulario */
      height: auto;
      width: 100%; /* Asegura que ocupe todo el ancho */
      margin: auto;
      margin-top: 2em;
    }
    
    .landing-peru-form {
      width: 100%;
      max-width: 1100px; /* Limita el ancho máximo del formulario */
      padding: 2em;
      display: grid;
      grid-template-columns: repeat(2, 1fr); /* Dos columnas */
      gap: 1em; /* Espacio entre campos */
      justify-items: center; /* Centra los elementos horizontalmente */
      background: white;
      border-radius: 12px; /* Bordes redondeados para un aspecto moderno */
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
      font-size: 1.2em;

    }
    
    .landing-peru-form-group {
      display: flex;
      flex-direction: column;
      width: 100%; /* Asegura que los grupos ocupen el 100% del ancho de la celda */
    }
    
    .landing-peru-form-group label {
      margin-bottom: 0.5em;

    }
    
    .landing-peru-form-group input,
    .landing-peru-form-group textarea {
      width: auto; /* Los campos de input ocupan el 100% del ancho de su celda */
      padding: 0.5em;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 1em;
    }
    
    .landing-peru-form-group textarea {
      grid-column: span 2; /* El textarea ocupa dos columnas */
      resize: vertical; /* Permite redimensionar verticalmente */
      width: auto;
    }
    
    .landing-peru-form-submit-button {
      grid-column: span 2; /* El botón ocupa dos columnas */
      padding: 1em 1.5em;
      margin: auto;
      background-color: #007bff;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      text-align: center; /* Centra el botón */
    }
    
    .landing-peru-form-submit-button:hover {
      background-color: #0056b3;
    }

  }

