/* Existing styles for page banner and title */

.page-banner {
  position: relative;
  width: 100vw;
  height: 100%; /* Full viewport height */
  overflow: hidden;
  background-color: #f8f9fa; /* Ensure a background color is set */
  background-size: cover; /* Ensure background covers entire area */
  background-position: center;
}

.page-banner-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -75%);
  color: white;
  font-size: 4rem;
  background: none;
  padding: 0.5em 1em;
  border-radius: 2px;
}

/* Styles for breadcrumb */
.page-banner-breadcrumb {
  position: absolute;
  bottom: 16px; /* Place breadcrumb at the bottom */
  left: 50%;
  transform: translateX(-50%);
  font-size: 16px;
  color: white;
}

.page-banner-breadcrumb-link {
  color: white;
  text-decoration: none;
}

.page-banner-breadcrumb-link:hover {
  text-decoration: underline;
}

.page-banner-breadcrumb-divider {
  margin: 0 8px;
  color: white;
}

.page-banner-breadcrumb-current {
  color: #aace3b; /* Green color for current page */
}

/* Responsive adjustments (for mobile and tablet) */
@media (min-width: 601px) and (max-width: 1024px) {
  .page-banner-title {
    font-size: 1.5em;
    transform: translate(-50%, -50%);
  }

  .page-banner-breadcrumb {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .page-banner-title {
    font-size: 1.2em;
    transform: translate(-50%, -50%);
  }

  .page-banner-breadcrumb {
    font-size: 12px;
  }
}
