.modelo-header-desktop-banner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 50vh;
    background-color: #f8f9fa;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    z-index: 1;
}

.modelo-header-desktop-info-container {
    display: flex;
    justify-content: center; /* Centra horizontalmente las dos columnas */
    align-items: flex-start; /* Alinea los textos hacia la parte superior */
    width: 80vw;
    margin: 3.5rem auto;
    padding: 4rem;
    z-index: 10;
}

.modelo-header-desktop-texto {
    flex: 1;
    text-align: right; /* Alineación del texto de la izquierda a la derecha */
    margin-right: 2rem; /* Espacio entre las columnas */
    z-index: 11; /* Asegura que los links estén por encima de otros elementos */
}

.modelo-header-desktop-title {
    font-size: 4rem;
    margin: 0;
    font-weight: 900;
    color: #303337;
    line-height: 80%;
}

.modelo-header-desktop-serie {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #666;
    font-weight: 600;
}

.modelo-header-desktop-serie a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    position: relative;
    z-index: 12; /* Para asegurarse de que el link esté encima */
}

.modelo-header-desktop-serie a:hover {
    text-decoration: none;
    color: inherit;
}

.modelo-header-desktop-description {
    flex: 1;
    text-align: left;
    font-size: 1.1rem;
    color: black;
    word-wrap: break-word;
    margin-left: 2rem;
    z-index: 11; /* Asegura que el texto no cubra el link */
}

.modelo-header-desktop-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateY(-60%);
    z-index: 2; /* Ajustamos para que no interfiera con el link */
}
.modelo-header-desktop-image {
    width: 640px;
    height: auto;
    border-radius: 8px;
    transition: transform 0.3s ease;
    filter: drop-shadow(0 8px 10px rgba(0, 0, 0, 0.3));
    z-index: 100;
}

.modelo-header-desktop-image:hover {
    transform: scale(1.05) rotate(1deg);
}

.modelo-header-desktop-ghost {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) skew(-10deg);
    font-size: 10rem;
    font-weight: 900;
    color: transparent;
    -webkit-text-stroke: 4px #aace3b;
    white-space: nowrap;
    z-index: 1;
    pointer-events: none;
}

.modelo-header-desktop-breadcrumb {
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 16px;
    color: white;
    z-index: 2;
}

.modelo-header-desktop-breadcrumb-link {
    color: white;
    text-decoration: none;
    z-index: 10; /* Aseguramos que el link esté visible */
}

.modelo-header-desktop-breadcrumb-link:hover {
    text-decoration: underline;
}

.modelo-header-desktop-breadcrumb-divider {
    margin: 0 8px;
    color: white;
}

.modelo-header-desktop-breadcrumb-current {
    color: #aace3b;
}
