/* src/components/HomeActionButton.css */

.home-action-button-container {
    display: flex;
    cursor: pointer;
    border-radius: 5px;
    overflow: hidden;
    font-family: 'Titillium Web', sans-serif;
    width: fit-content;
    text-decoration: none; /* Remove underline */
}

.home-action-button-container:hover {
    text-decoration: none; /* Ensure underline doesn't appear on hover */
}
.home-action-button-content {
    background-color: #4A4A49; /* Dark grey background */
    color: #A3C537; /* Lime green text */
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold; /* Make the text bold to match the design */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 3; /* This will take 75% of the space */
}

.home-action-button-arrow {
    background-color: #A3C537; /* Lime green background for the right side */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    flex-grow: 1; /* This will take 25% of the space */
    position: relative;
}

.home-action-button-arrow-icon {
    color: #4A4A49; /* Dark grey color for the arrow */
    font-size: 16px;
    position: relative;
}
