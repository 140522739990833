/* Contenedor principal de la sección */
.home-contacto-section-desktop-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  background-image: url('../../../../images/HomeContactoSectionDesktop/home-contacto-section-background.webp');
  /*  background: linear-gradient(135deg, #BBC8CE, #8597A3);  Gradiente de gris claro a gris oscuro */
  overflow: hidden;
  background-size: cover;
}

/* Mitad izquierda: Mapa */
.home-contacto-section-desktop-mapa-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent; /* Eliminar fondo */
  width: 50%;
}

/* Mitad derecha: Contenedor de la tarjeta y el título */
.home-contacto-section-desktop-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centra verticalmente el contenido */
  align-items: left; /* Centra horizontalmente el contenido */
  padding: 2rem;
  background-color: transparent; /* Eliminar fondo */
  width: 50%;
}
