/* src/components/Modelo/ModeloDestacadoDesktop/ModeloDestacadoDesktop.css */
.modelo-destacado-section {
  display: flex;
  flex-direction: column;
  width: 100vw;
  background-color: #F8F9FA;;
}

.modelo-destacado-container {
  display: flex;
  flex-direction: column;
  width: 60vw;
  margin: 2rem auto;
}

.modelo-destacado-title {
  text-align: left;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  margin-top: 0rem;
}

.modelo-destacado-desktop {
  display: flex;
  justify-content: space-between;
  gap: 0rem;
}

.modelo-destacado-card-wrapper {
  flex: 1;
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.modelo-destacado-card-wrapper.visible {
  opacity: 1;
  transform: translateY(0);
}