/* Add shrinking animation when clicked */
.clicked .home-linea-slider-desktop-item {
  transform: scale(0.80);
  transition: transform 0.2s ease-in-out;
}

/* Default styles for images */
.home-linea-slider-desktop-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: filter 0.3s ease-in-out, transform 0.2s ease-in-out;
  position: relative; /* Necesario para overlay en hover */
}

/* Default styles for images */
.home-linea-slider-desktop {
  width: 80%;
  margin: 0 auto;
}

/* Image wrapper */
.home-linea-slider-desktop-image-wrapper {
  width: 178px;
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
}

/* Cuando el ítem está seleccionado, se queda el overlay verde */
.home-linea-slider-desktop-item.selected::before {
  opacity: 1; /* El overlay verde se mantiene al estar seleccionado */
}

/* Fondo verde cuando el ítem está seleccionado */
.home-linea-slider-desktop-item.selected .home-linea-slider-desktop-image-wrapper {
  background-color: rgba(185, 247, 70, 0.507); /* #ABCF3A with 50.7% opacity */
  transition: background-color 0.3s ease-in-out;
}

/* Grayscale for unselected items */
.home-linea-slider-desktop-item img {
  filter: grayscale(100%);
  transition: filter 0.3s ease-in-out;
  width: 178px;
  height: 104px;
}

/* Full color for selected items */
.home-linea-slider-desktop-item.selected img {
  filter: grayscale(0%);
  transition: filter 0.3s ease-in-out;
}

/* Hover effect with a semi-transparent green overlay */
.home-linea-slider-desktop-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 178px;
  height: 104px;
  background-color: #b7ff51; /* Verde claro con opacidad */
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  mix-blend-mode: multiply;
  z-index: 2; /* Asegura que el overlay esté sobre la imagen */
}

/* Mostrar el overlay en hover */
.home-linea-slider-desktop-item:hover::before {
  opacity: 1; /* Aparece el overlay verde al hacer hover */
}

/* Imagen visible debajo del overlay */
.home-linea-slider-desktop-item img {
  z-index: 1;
}

/* Tablet styles (768px <= width < 1024px) */
@media (min-width: 768px) and (max-width: 1199px) {

  .home-linea-slider-desktop-wrapper {
    margin-top: 5rem;
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .home-linea-slider-desktop-arrow {
    width: 48px;
    height: 48px;
  }

  .home-linea-slider-desktop-prev {
    left: -4rem;
  }

  .home-linea-slider-desktop-next {
    right: -4rem;
  }

  .home-linea-slider-desktop-arrow-image {
    width: 100%;
    height: 100%;
  }

  .home-linea-slider-desktop-image {
    width: 178px;
    height: 104px;
  }

  .home-linea-slider-desktop-label {
    font-size: 0.9rem;
    font-family: 'Titillium Web', sans-serif;
    font-weight: bold;
  }

  .home-linea-slider-desktop-slide-button {
    border: none;
    background: none;
  }
  
  .home-linea-slider-desktop-label{
    position: absolute;
    top: 30%;
    z-index: 1000;
    color: white;
  }

}


/* Desktop styles (width >= 1024px) */
@media (min-width: 1200px) {

  .home-linea-slider-desktop-wrapper {
    margin-top: 6rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 90vw;
  }

  .home-linea-slider-desktop-arrow {
    width: 6rem;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
  }

  .home-linea-slider-desktop-prev {
    left: -5rem;
  }

  .home-linea-slider-desktop-next {
    right: -5rem;
  }

  .home-linea-slider-desktop-arrow-image {
    width: 50%;
    height: 50%;
    object-fit: contain;
  }

  .home-linea-slider-desktop-image {
    width: 92px;
    height: 92px;
    object-fit: cover;
  }

  .home-linea-slider-desktop-label {
    margin-top: 0;
    font-size: 1rem;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: 'Titillium Web', sans-serif;
  }

  .home-linea-slider-desktop-slide-button {
    border: none;
    background: none;
  }

  .home-linea-slider-desktop-item:hover {
    transform: none; /* Eliminar el escalado */
    transition: none;
  }

  .home-linea-slider-desktop-item {
    transform: scale(1); /* Reset a escala normal */
    transition: transform 0.2s ease-in-out;
  }

}

