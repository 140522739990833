/* Estilos generales para la tarjeta de contacto móvil */
.home-contacto-card-mobile {
  padding: 1.25rem;
  background-color: transparent; /* Eliminar fondo */
  border-radius: 0.5rem;
  border: 1px solid white; /* Borde blanco */
  box-shadow: 0 0.25rem 0.625rem rgba(0, 0, 0, 0.1);
  width: 80%; /* Hacer que el ancho de la tarjeta sea flexible */
  max-height: 25rem; /* Aumentar la altura máxima en móviles */
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white; /* Todo el texto blanco */
  margin: 0 auto; /* Centrar la tarjeta horizontalmente */
}

/* Botón en el fondo de la tarjeta */
.home-contacto-card-mobile-button-container {
  margin: 1rem auto;
}

.home-contacto-card-mobile-button {
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
  font-family: 'Titillium Web', sans-serif;
  width: fit-content;
  margin-top: auto; /* Para que el botón se coloque en el fondo de la tarjeta */
}

.home-contacto-card-mobile-button-content {
  background-color: #4a4a49;
  color: #a3c537;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 3;
}

.home-contacto-card-mobile-button-arrow {
  background-color: #a3c537;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  flex-grow: 1;
  position: relative;
}

.home-contacto-card-mobile-button-arrow-icon {
  color: #4a4a49;
  font-size: 16px;
  position: relative;
}

/* Cabecera de la tarjeta de contacto */
.home-contacto-card-mobile-header {
  display: flex;
  align-items: center; /* Alinear ítems verticalmente */
  margin-bottom: 1rem;
  justify-content: center; /* Centrar el contenido horizontalmente */
  width: 100%;
  height: auto;
  padding: 0px;
}

/* Título de la tarjeta */
.home-contacto-card-mobile-title {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  text-align: center; /* Centrar el texto */
  color: white;
  max-width: 90%; /* El ancho al 100% ayudará a que el título ocupe el espacio adecuado */
  flex-grow: 1; /* Hace que el título ocupe todo el espacio disponible dentro del contenedor flex */

}

/* Selector de país */
.home-contacto-card-mobile-selector {
  background-color: transparent;
  color: white !important;
  padding: 0.625rem;
  border-radius: 0.3125rem;
  border: 1px solid white;
  width: 100%;
  font-size: 1rem;
  text-align: left;
}

/* Estilos de la lista del selector desplegable */
.home-contacto-card-mobile-selector option {
  background-color: white; /* Fondo blanco para la lista */
  color: black; /* Texto negro para las opciones */
}

/* Lista de detalles de la oficina */
.home-contacto-card-mobile-details ul {
  list-style: none;
  padding: 0;
  text-align: left; /* Alinear la lista a la izquierda */
}

/* Elementos de la lista de detalles */
.home-contacto-card-mobile-details li {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  text-align: left; /* Alinear cada elemento de la lista a la izquierda */
}
