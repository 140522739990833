/* Main container */
.product-list-desktop-container {
  display: flex;
  flex-direction: column;
  padding: 0 1rem 1rem;
  width: 100%;
  margin-top: 2rem;
  background-color: white;
}

.product-list-desktop-title {
  font-size: 2rem;
  font-weight: 500;
  color: #AACE39;
  line-height: 3.5rem;
}

.product-list-desktop-list {
  list-style: none;
  padding: 0;
}

/* Línea general */
.product-list-desktop-linea-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 400;
  line-height: 0px;
  background-color: inherit; /* Fondo por defecto */
  color: inherit; /* Texto por defecto */
}

.product-list-desktop-linea-header:hover {
  background-color: #e7e7e7; /* Fondo hover */
}

/* Icono por defecto */
.product-list-desktop-linea-header .material-icons {
  font-size: 24px;
  color: #4A4A49;
}

/* Línea seleccionada */
.product-list-desktop-linea-header.selected-linea {
  background-color: #AACE39; /* Fondo seleccionado */
  color: #000000; /* Texto seleccionado */
  font-weight: 600;
}

/* Icono en la línea seleccionada */
.product-list-desktop-linea-header.selected-linea .material-icons {
  color: #000000;
}

/* Lista de series */
.product-list-desktop-series-list {
  list-style: none;
  padding-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.product-list-desktop-series-list li {
  cursor: pointer;
  padding: 5px;
  transition: background-color 0.3s ease;
}

/* Hover en series */
.product-list-desktop-series-list li:hover {
  background-color: #4A4A49;
  color: #ffffff;
}

/* Serie seleccionada */
.selected-serie {
  font-weight: 600;
  background-color: #AACE39;
  color: #000000;
}

/* Botón de restablecer */
.product-list-desktop-reset-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #AACE39;
  color: #4A4A49;
  border: none;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: bold;
  width: auto;
  max-width: 200px;
  align-self: center;
}

.product-list-desktop-reset-button:hover {
  background-color: #8eb72e;
}
