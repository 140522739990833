/* src/components/ModeloTable.css */

.modelo-table-container {
  margin-top: 4rem;
  width: 100%;
}

.modelo-table-title {
  text-align: left; /* Alinea el título a la izquierda */
  font-size: 2rem; /* Tamaño del título */
  margin-bottom: 1.5rem; /* Espacio entre el título y la tabla */
}

.modelo-specs-table {
  width: 100%; /* Hace la tabla más ancha */
  max-width: 1200px; /* Limita el ancho máximo */
  border-collapse: collapse;
  margin: 0 auto;
}

.modelo-specs-table th {
  background-color: #343a40; /* Cabecera oscura */
  color: white;
  padding: 10px;
}

.modelo-specs-table td {
  padding: 8px;
  border: 1px solid #ddd;
}

.modelo-specs-table tr:nth-child(even) {
  background-color: #f2f2f2; /* Fila par */
}

.modelo-specs-table tr:nth-child(odd) {
  background-color: #ffffff; /* Fila impar */
}

@media (max-width: 768px) {
  .modelo-specs-table {
    width: 100%; /* Asegura que la tabla se ajuste en móviles */
  }

  .modelo-table-container {
    margin-top: 2rem;
  }
}
