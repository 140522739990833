.noticia-card-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid #ddd;
  border-radius: 7px;
  width: 300px;
  height: 413px;
  text-align: left;
  background-color: #faf8f8;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Sombra más profunda */
  transition: box-shadow 0.3s ease; /* Suaviza la transición de la sombra al pasar el mouse */
}

.noticia-card-container:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3); /* Sombra más intensa al hacer hover */
}

.noticia-card-image-container {
  width: 100%;
  height: 220px;
  overflow: hidden;
  border-radius: 5px;
}

.noticia-card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.noticia-card-title {
  font-size: 1rem;
  margin-top: 1rem;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 6em;
  font-weight: 500;
  padding-top: 0;
  padding-left: 1rem;
  max-width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.noticia-card-title::before {
  content: '';
  display: block;
  width: 40px; /* Ajusta el ancho de la línea */
  height: 3px; /* Ajusta el grosor de la línea */
  background: linear-gradient(to right, black 20%, #aace3b 20%); /* 20% negro y 80% verde */
  margin-bottom: 8px; /* Espacio entre la línea y el título */
}

.noticia-card-button-wrapper {
  margin: 0rem auto 0.4rem 1rem;
}

.noticia-card-button {
  display: inline-block;
  padding: 0.1rem 0.6rem;
  background-color: #aace3b;
  color: #343a40;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 400;
  transition: background-color 0.3s ease;
}

.noticia-card-button:hover {
  background-color: #94b52f;
}
