/* General styles */
body {
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  margin: 0;
  padding: 0;
}

/* Login form styles */
.leads-page-login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f9f9f9;
}

.leads-page-login-form {
  background: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 350px;
  text-align: center;
}

.leads-page-login-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.leads-page-login-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.leads-page-login-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.leads-page-login-button:hover {
  background-color: #0056b3;
}

.leads-page-error {
  color: red;
  font-size: 14px;
}

.leads-page-container {
  padding: 20px;
}

.leads-page-title {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 4rem;
}