/* General styles for the grid */
.product-grid-container {
  width: 100%;
  padding: 1rem;
}

.product-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-top: 1rem;
  padding-top: 1rem;
  min-height: 300px; /* Ensure space for loading messages */
}

.product-grid-message {
  font-size: 1.2rem;
  color: #555;
  text-align: center;
  padding: 2rem;
  width: 50%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 8px;
}

/* Breadcrumb styles */
.product-grid-breadcrumb {
  font-size: 1.5rem;
  text-align: left;
  margin-bottom: 1rem; /* Space below the breadcrumb */
  padding-left: 2.5rem; /* Padding to align the breadcrumb to the left */
  font-weight: 400;
}

/* Specific styles for lineaName and serieName */
.linea-name {
  color: #333; /* Color for the linea */
}

.serie-name {
  color: #AACE39; /* Color for the serie */
}

/* Responsive styles for breadcrumb */
@media (max-width: 768px) {
  .product-grid-breadcrumb {
    font-size: 1.5rem;
    padding-left: 0rem; /* Padding to align the breadcrumb to the left */
  }
}

@media (max-width: 480px) {
  .product-grid-breadcrumb {
    font-size: 1.5rem;
    padding-left: 0rem; /* Padding to align the breadcrumb to the left */
  }
}

/* Responsive styles for messages */
@media (max-width: 768px) {
  .product-grid-message {
    font-size: 1.5rem;
    padding: 1rem;
    width: 300px;
    height: auto;
  }
}

@media (max-width: 480px) {
  .product-grid-message {
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
    width: 100%;
    height: auto;
  }
}
