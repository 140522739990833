/* src/App.css */

/* Global styles */
body, html, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: 'Titillium Web', sans-serif;
}

/* Asegura que los placeholders usen la fuente 'Titillium Web' */
::placeholder {
  font-family: 'Titillium Web', sans-serif;
  color: #999; /* Ajusta el color según tu diseño */
}

/* Firefox specific placeholder styling */
:-moz-placeholder {
  font-family: 'Titillium Web', sans-serif;
  color: #999;
}

/* Internet Explorer specific placeholder styling */
:-ms-input-placeholder {
  font-family: 'Titillium Web', sans-serif;
  color: #999;
}

/* Safari specific placeholder styling */
::-webkit-input-placeholder {
  font-family: 'Titillium Web', sans-serif;
  color: #999;
}

/* Default styles for non-home pages */
.default-page {
  height: auto;
  overflow-x: hidden; /* Allow scrolling on other pages */
}

/* Home-specific styles */
.home-page {
  height: 100%;
  overflow: hidden; /* Restrict scrolling on the home page */
}
