/* src/components/HomeNoticiasSectionDesktop.css */

.home-noticias-section-desktop {
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center;     /* Centra verticalmente */
  height: 100%;       /* Asegura que ocupe toda la altura de la ventana */
  width: 100%;
  background-color: #f8f9fa;
  overflow: hidden
}

.home-noticias-section-desktop-container {
  padding: 4rem;
  width: 60%;
  margin: 0 auto;
}

.home-noticias-section-desktop-title {
  font-size: 3rem;
  color: #393A43;
  margin: 2rem auto 1rem;
  text-align: center;
}

.home-noticias-section-desktop-grid {
  display: flex;
  margin-top: 4rem;
  gap: 1rem;
  justify-content: space-around;
  flex-wrap: wrap;
}

/* Botón Ver Más */
.home-noticias-section-desktop-ver-mas-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
}

.home-noticias-section-desktop-ver-mas {
  display: inline-block;
  width: 140px;
  height: 42px;
  background-color: #393A43;
  color: #AACE3B;
  text-align: center;
  line-height: 42px; /* Asegura que el texto esté centrado verticalmente */
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.3s ease;

}

.home-noticias-section-desktop-ver-mas:hover {
  background-color: #2f2f36; /* Cambio de color al hacer hover */
}
