.servicios-accordeon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: #F8F8F8;
}

.servicios-accordeon-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1200px;
}

.servicios-accordeon-left {
  flex: 1;
}

.servicios-accordeon-item {
  margin: 1rem 0;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  padding-bottom: 0.5rem;
}

.servicios-accordeon-item h3 {
  font-size: 1.5rem;
  margin: 0;
  transition: color 0.3s ease;
}

.servicios-accordeon-item.active h3 {
  color: #000;
}

.servicios-accordeon-item p {
  margin: 0.5rem 0 0;
  color: #666;
}

.servicios-accordeon-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Alinea la imagen al tope */
  margin-top: 2rem;
}

.servicios-accordeon-image {
  width: 400px;
  height: 300px;
  object-fit: cover;
  border-radius: 5px;
}

/* Responsive */
@media (max-width: 768px) {
  .servicios-accordeon-content {
    flex-direction: column;
    align-items: center;
  }

  .servicios-accordeon-right {
    margin-top: 1.5rem;
    align-items: center; /* Vuelve a centrar la imagen en móvil */
  }
}
