/* src/components/ModelCard.css */

.model-card {
  padding: 20px;
  width: 300px; /* Ancho fijo en desktop */
  max-width: 100%; /* Asegura que no se sobrepase en mobile */
  height: 450px; /* Altura fija para contener contenido sin estirarse */
  text-align: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: box-shadow 0.3s ease, border 0.3s ease; /* Transición suave para la sombra y borde */
  overflow: hidden; /* Para evitar que la imagen crecida salga del contenedor */
  position: relative; /* Necesario para usar pseudo-elementos */
}

.model-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0; /* Inicialmente el borde superior no tiene altura */
  background-color: #A3C537; /* Color del efecto líquido */
  z-index: 0; /* Asegura que el pseudo-elemento esté detrás del contenido */
  transition: height 0.3s ease-in-out; /* Animamos el crecimiento de la altura */
}

.model-card:hover::before {
  height: 10px; /* El "líquido" crece solo 10px al hacer hover */
}

.model-card:hover {
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15); /* Sombra suave en hover */

}

.model-card-image {
  width: 100%;
  height: 200px; /* Mantén la altura de la imagen */
  object-fit: cover;
  margin-bottom: 0%;
  transition: transform 0.3s ease; /* Transición para el crecimiento */
}

.model-card:hover .model-card-image {
  transform: scale(1.1); /* Crece un 10% al hacer hover */
}

.model-card-name {
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 0px;
  margin-top: 10px;
  font-weight: 900;
}

.model-card-serie {
  color: #aace3b;
  font-size: 1rem;
  margin-bottom: 10px;
  font-weight: 300;
}

.model-card-specs {
  list-style: none;
  padding: 0;
  margin: 10px 0;
  font-size: 0.9rem;
  text-align: center; /* Alineación del texto a la izquierda */
  display: flex;
  flex-direction: column;
  align-items: center; /* Alinea la lista de specs al centro del card */
  height: 100px; /* Altura fija para controlar el tamaño de los specs */
  overflow-y: auto; /* Añade un scrollbar si hay más contenido */
}

.model-card-specs li {
  width: 100%; /* Los items ocupan todo el ancho del card */
  margin-bottom: 5px; /* Espaciado entre especificaciones */
}

.model-card-spec-name {
  color: #A3C537;            /* Green color for the spec name */
  font-weight: 600;       /* Bold for emphasis */
  margin-right: 5px;       /* Spacing between name and value */
}

/* Estilo para el mensaje de no hay especificaciones */
.no-specs-message {
  font-size: 1.2rem; /* Hacemos el texto un poco más grande */
  text-align: center; /* Centramos el texto */
  width: 100%; /* Ocupa el ancho completo */
  color: #777; /* Color más suave */
  padding-top: 20px;
}

.model-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.model-card-button {
  flex: 1;
  margin: 0 5px;
  text-align: center;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
  transition: all 0.3s ease;
  cursor: pointer;
}

/* Botón VER MÁS */
.vermas-button {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  background-color: #A3C537; /* Color inicial */
  color: #4A4A49;
  text-decoration: none; /* Desactiva el subrayado */
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
  overflow: hidden; /* Necesario para el efecto de llenado */
  transition: color 0.3s ease; /* Transición para el color del texto */
  z-index: 1; /* Asegura que el texto esté por encima del pseudo-elemento */
}

.vermas-button::before {
  content: '';
  position: absolute;
  top: 100%; /* Empieza fuera del botón */
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #218838; /* Color de la sustancia "líquida" */
  transition: top 0.3s ease-in-out; /* Transición para el efecto de llenado */
  z-index: -1; /* Asegura que el pseudo-elemento esté detrás del texto */
}

.vermas-button:hover::before {
  top: 0; /* Hace que el "líquido" suba desde el fondo hacia arriba */
}

.vermas-button:hover {
  color: white; /* Cambia el color del texto cuando se hace hover */
  text-decoration: none; /* Asegura que no haya subrayado en hover */
}

/* Botón DETALLES (modal-button) */
.modal-button {
  background-color: transparent; /* Sin fondo */
  color: black; /* Color del texto y del borde */
  border: 1px solid black; /* Borde del color actual */
  font-weight: 400;
}

.modal-button:hover {
  font-weight: 600;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .model-card {
    width: 100%; /* Ancho completo en mobile */
    height: auto; /* Altura ajustable en mobile */
  }
}