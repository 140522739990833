/* Estilos generales para desktop (>= 768px) */
.modelo-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem auto 0;
  max-width: 80vw;
}

.modelo-page-alerta{
  margin-top: 60px;
}

.modelo-page-header {
  width: 100vw;
  height: auto;
  background-color: #f8f9fa;
  margin-bottom: 2rem;
}

.modelo-page-detail {
  padding: 20px;
  margin-top: 0rem;
}

.modelo-page-image-container {
  text-align: center;
  margin-bottom: 20px;
}

.modelo-page-image {
  max-width: 100%;
  height: auto;
}

.modelo-page-specs-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 2rem;
}

.modelo-page-footer {
  margin-top: 0rem;
}

.modelo-page-specs-table th,
.modelo-page-specs-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

/* src/Modelo.css */
.modelo-page-buttons-container {
  display: flex;
  justify-content: center; /* Centra los botones */
  width: 100%;
  max-width: 40vw;
  margin: 2rem auto;
}

.modelo-page-loading {
  text-align: center;
  padding: 20px;
}

.modelo-page-error {
  color: red;
  text-align: center;
}

.modelo-page-specs-table th {
  background-color: #343a40;
  color: white;
}

.modelo-page-specs-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.modelo-page-specs-table tr:nth-child(odd) {
  background-color: #ffffff;
}

/* Estilos específicos para pantallas menores a 768px */
@media (max-width: 767px) {
  .modelo-page-header {
    height: 100vh; /* Altura predeterminada en pantallas móviles */
    padding-bottom: 1rem;
    /*border: 1px red dotted;*/
  }

    .modelo-page-buttons-container {
    flex-direction: column;
    align-items: center;
    max-width: 80vw;
    margin: 1.5rem auto;
  }

  /* Clase condicional para cuando no hay descripción */
  .reduced-header-mobile {
    height: 70vh; /* Reducimos el tamaño a 70vh si no hay descripción */
  }

  .modelo-page-detail {
    padding: 2rem 10px; /* Reduce el padding en pantallas más pequeñas */
  }

  .modelo-page-specs-table th,
  .modelo-page-specs-table td {
    padding: 6px; /* Reduce el padding en las celdas de la tabla */
  }

  .modelo-page-button {
    padding: 8px 16px; /* Reduce el padding del botón */
  }

  .modelo-page-container {
    margin-top: 1rem;
    padding-top: 0rem;
  }

  .modelo-page-buttons {
    margin-top: 15px;
  }

  .modelo-page-detail {
    padding: 0px;
    margin-top: 2rem;
  }

  .modelo-page-footer {
    margin-top: 2rem;
  }
}
