.home-serie-slider-desktop-background{
  background-image: url(https://en.zoomlion.com/resources/web/img/ipt1_03.jpg);
  background-size: cover;  /* Ensures the image covers the entire div */
  background-position: center;  /* Centers the image */
  background-repeat: no-repeat;  /* Prevents image repetition */
  /*border: 1px cyan solid;*/
  height: 100%;
}

.home-serie-slider-desktop-zoomlion{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -10%);
  /*border: 1px cyan solid;*/
  height: auto;
  width: 80%;
  }

.home-serie-slider-desktop-wrapper {
/*  border: 1px green dotted;*/
  height: 100%;
}

.home-serie-slider-desktop-container {
  width: 60vw;
  margin: 0rem auto;
  transform: translateY(-5%);
}

/* Default styles for images */
.home-serie-slider-desktop-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-serie-slider-desktop-button {
  background-color: #333740;
  color: #A2CE64;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  margin-top: -1rem;
  z-index: 2000;
  transition: background-color 0.3s ease, color 0.3s ease; /* Transición suave */
}

.home-serie-slider-desktop-button:hover {
  background-color: #A2CE64; /* Cambia a verde */
  color: #333740; /* Cambia el texto a gris oscuro */
  border: none;
}

.home-serie-slider-desktop-unavailable {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8d7da; /* Fondo rojo claro */
  color: #721c24;           /* Texto rojo oscuro */
  border: 1px solid #f5c6cb; /* Borde rojo */
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
}

  /* Mobile Horizontal (481px a 768px) */
  @media (min-width: 481px) and (max-width: 768px) {
  
  }
  



/* Tablet styles (768px <= width < 1199px) */
@media (min-width: 768px) and (max-width: 1199px) {
  .home-serie-slider-desktop-wrapper {

  }

  .home-serie-slider-desktop-arrow {
    width: 2.5rem;
    height: 2.5rem;
  }

  /* Adjust positioning of arrows for tablet */
  .home-serie-slider-desktop-prev {
    left: -1.5rem;
  }

  .home-serie-slider-desktop-next {
    right: -1.5rem;
  }

  .home-serie-slider-desktop-arrow-image {
    width: 60%;
    height: 60%;
  }

  .home-serie-slider-desktop-image {
    width: 600px;
    height: 400px;
    margin-top: 1em;
  }

  .home-serie-slider-desktop-label {
    font-size: 1.2em;
    font-family: 'Titillium Web', sans-serif;
    position: absolute;
    top: -5%;
    font-weight: 600;
    text-transform: uppercase; /* Makes the text uppercase */
  }

  .home-serie-slider-desktop-button {
    background-color: #333740;
    color: #A2CE64;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 1em;
    z-index: 2000;
    transition: background-color 0.3s ease, color 0.3s ease; /* Transición suave */
  }

}

/* Desktop styles (width >= 1024px) */
@media (min-width: 1200px) {


  /* Move arrows closer to the image */
  .home-serie-slider-desktop-arrow {
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    cursor: pointer;
    top: 60%;
    transform: translateY(-50%);
  }

  .home-serie-slider-desktop-arrow-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform: scale(1.10); /* Grow by 20% on hover */
    transition: transform 0.2s ease-in-out;
  }

  /* Adjusted image size */
  .home-serie-slider-desktop-image {
    width: 700px;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;  /* Smooth grow effect */
    margin-top: 1.5em;
  }

  /* Only apply the hover effect to the image */
.home-serie-slider-desktop-image:hover {
  transform: scale(1.05);   /* Image grows on hover */
}

  .home-serie-slider-desktop-label {
    margin-top: 0;
    font-size: 2.5rem;
    font-weight: 600;
    font-family: 'Titillium Web', sans-serif;
    text-transform: uppercase; /* Makes the text uppercase */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transform: translateY(4rem);
    color: #333740;

  }

  .home-serie-slider-desktop-slide-button {
    border: none;
    background: none;
  }
}