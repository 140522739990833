/* Contenedor principal de la sección */
.home-contacto-section-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  background-image: url(/public/images//HomeContact/home-contact-background.webp);
/*  background: linear-gradient(135deg, #BBC8CE, #8597A3);  Gradiente de gris claro a gris oscuro */
  overflow: hidden;
  background-size: cover;
}

/* Para dispositivos más pequeños (responsive design) */
@media (max-width: 768px) {
  .home-contact-section-container {
    flex-direction: column;
  }

}
