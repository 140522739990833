/* PageNavbarDesktop.css */

.page-navbar {
  position: fixed;
  top: 0;
  width: 100vw;
  background-color: white;
  z-index: 1000;
  padding: 0;
  color: black;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Navbar transparente para la sección 1 */
.page-navbar-transparent {
  background-color: transparent !important;
  color: white !important;
}

/* Contenido del navbar */
.page-navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 2rem;
  box-sizing: border-box;
}

/* Logo */
.page-navbar-logo img {
  height: 40px;
}

.page-navbar-logo {
  margin-right: 1rem;
}

/* Contenedor de los links y búsqueda, alineado a la derecha */
.page-navbar-links-container {
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 2rem;
}

/* Desktop navigation links */
.page-navbar-links-desktop {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-right: 1rem;
  transition: color 0.3s ease;
}

.page-navbar-links-desktop a {
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  color: black;
  transition: color 0.3s ease;
}

.page-navbar-links-desktop a:hover {
  color: #aace39;
}

/* Links blancos cuando la navbar es transparente */
.page-navbar-transparent .page-navbar-links-desktop a {
  color: white !important;
}

.page-navbar-transparent .page-navbar-links-desktop a:hover {
  color: #aace39 !important;
}

/* Search bar container */
.page-navbar-search {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* Search bar */
.page-navbar-search-bar {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 0.5rem;
  border-radius: 5px;
  background-color: #f9f9f9;
  width: 20rem;
  max-width: 350px;
}

.page-navbar-search-bar input {
  border: none;
  outline: none;
  padding: 0.5rem;
  font-size: 14px;
  width: 100%;
  font-family: 'Titillium Web', sans-serif;
}

.page-navbar-search-icon {
  cursor: pointer;
  margin-left: 10px;
}

.page-navbar-search-close-icon,
.page-navbar-search-button-icon {
  cursor: pointer;
  margin-left: 5px;
}

/* Updated alignment for search results */
.page-navbar-search-results {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  border-radius: 5px;
  overflow: hidden;
}

.page-navbar-search-result-item {
  padding: 10px;
  border-bottom: 1px solid #eee;
  text-align: center;
}

.page-navbar-search-result-item a {
  text-decoration: none;
  color: black;
}

.page-navbar-search-result-item:hover {
  background-color: #f0f0f0;
}

.page-navbar-search-result-item a:hover {
  text-decoration: underline;
}

/* Centering the 'No results found' message */
.page-navbar-search-no-results {
  text-align: center;
  padding: 10px;
  color: grey;
  font-weight: 400;
}

.page-navbar-search-view-more {
  text-align: center;
  cursor: pointer;
  padding: 10px;
  background-color: #f9f9f9;
  color: #A4CE4E;
  font-weight: 400;
}
