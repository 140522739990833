/* src/components/ModeloSpecs.css */

.modelo-specs-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.modelo-specs-title {
  text-align: left; /* Alinea el título a la izquierda */
  font-size: 2rem; /* Tamaño del título */
  margin-bottom: 1.5rem; /* Espacio entre el título y las tarjetas */
  margin-top: 0rem;
}

.modelo-spec-name {
  font-weight: 300;
  margin-bottom: 5px;
  font-size: 1.1rem;
  min-width: 200px;
  white-space: nowrap; /* Evita el salto de línea */
}

.modelo-spec-value {
  font-size: 1.5rem;
  color: #aace3b;
  font-weight: 600;
}

.modelo-specs {
  display: flex;
  justify-content: space-between;
  gap: 2rem; /* Espacio entre las tarjetas */
}

.modelo-spec {
  flex: 1;
  text-align: center;
  padding: 1rem 1rem 0;
  width: 100%;
  /*background-color: #f9f9f9; /* Fondo claro para las tarjetas */
  /*box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Sombra ligera para el efecto de tarjeta */
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, font-weight 0.3s ease-in-out;
}

.modelo-spec.visible {
  /* Estilo aplicado cuando el elemento es visible */
  opacity: 1;
  transform: translateY(0);
}

/* Efecto hover en escritorio */
@media (min-width: 769px) {
  .modelo-spec:hover {
    transform: scale(1.05); /* Crece ligeramente */
    box-shadow: 0 4px 10px rgba(170, 206, 59, 0.4); /* Sombra acentuada con color #aace3b */
  }
}

/* Definimos la animación de fade-in con desplazamiento */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px); /* Inicia más abajo */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Llega a su posición original */
  }
}

@media (max-width: 768px) {
  .modelo-specs {
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  .modelo-spec {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    width: auto;
  }

  .modelo-specs-container {
    width: 90vw;
    margin-bottom: 0rem;
  }
}
