/* Banner and General Layout */

.concurso-page{

}

.concurso-page-banner {
    width: 100vw;
    height: 24rem;
    background-color: #f8f9fa;
  }
  
  /* Términos and Condiciones Styling */
  .concurso-page-terminos-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    min-height: 100%; /* Ensures the section fills the height of the page */
    width: 90%;
    overflow: hidden;
    margin: 0 auto;
  }
  
  .concurso-page-terminos {
    max-width: 800px; /* Limit the width of the text for better readability on larger screens */
    text-align: justify;
    font-size: 1rem;
    padding: 2rem;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for emphasis */
    border-radius: 8px;
    margin: 0 auto; /* Ensure it's centered horizontally */
  }
  
  /* Mobile-specific styling */
  @media (max-width: 767px) {
    .concurso-page-terminos-container {
      width: 90vw;
      padding: 1rem; /* Adjust padding for smaller screens */
    }
  
    .concurso-page-terminos {
      width: 90%; /* Limit width to 90% of the viewport for better fit */
      padding: 1rem; /* Slightly smaller padding for smaller screens */
      margin: 0 auto; /* Ensure it's centered horizontally */
    }
  
    .concurso-page-banner {
      height: 10rem; /* Adjust the banner height for mobile */
    }
  }
  
  /* Styling for larger screens */
  @media (min-width: 768px) {
    .concurso-page-terminos-container {
      padding: 4rem;
    }
  
    .concurso-page-terminos {
      width: 90%; /* Maintain a 90% width on larger screens */
      margin: 0 auto; /* Ensure it's centered horizontally */
    }
  }
  