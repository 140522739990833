.page-navbar-mobile {
  position: fixed;
  top: 0;
  width: 100vw;
  background-color: white;
  z-index: 1000;
  padding: 0;
  color: black;
}

.page-navbar-mobile-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 2rem;
  box-sizing: border-box;
}

.page-navbar-mobile-logo img {
  height: 40px;
}

.page-navbar-mobile-hamburger {
  cursor: pointer;
}

.page-navbar-mobile-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100vw;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

.page-navbar-mobile-menu a {
  text-decoration: none;
  padding: 1rem;
  text-align: center;
  color: black;
  transition: background-color 0.3s ease;
}

.page-navbar-mobile-menu a:hover {
  background-color: #f0f0f0;
}

.page-navbar-mobile-search {
  position: relative;
  margin-left: auto; /* Asegura que el botón de búsqueda esté pegado a la derecha */

}

.page-navbar-mobile-search-modal {
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 1001;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center; 
  box-sizing: border-box; 
}

.ver-mas-productos {
  text-align: center;
  padding: 10px;
  background-color: white;
  color: #A3C537;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: 400;
}

.ver-mas-productos:hover {
  background-color: #e0e0e0;
}


.page-navbar-mobile-search-bar {
  display: flex;
  align-items: center;
  justify-content: center; /* Centra el input horizontalmente */
  border: 1px solid #ccc;
  padding: 0.3rem;
  border-radius: 5px;
  background-color: #f9f9f9;
  width: 90%; /* Mantiene el ancho de 80% */
  margin: 0 auto; /* Esto centrará la barra de búsqueda en su contenedor padre */
  box-sizing: border-box;
}


.page-navbar-mobile-search-bar input {
  border: none;
  outline: none;
  padding: 0.5rem;
  font-size: 14px;
  width: 100%; /* Ocupa el 100% del espacio disponible en el contenedor */
  font-family: 'Titillium Web', sans-serif;
}

.page-navbar-mobile-search-results {
  position: fixed;
  left: 0;
  width: 100%; /* Mantiene el ancho completo */
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 18rem;
  overflow: hidden;
  z-index: 1002;
  text-align: center; /* Centra el contenido dentro del contenedor */
}


/* Asegúrate de que los elementos de resultado ocupen todo el ancho */
.page-navbar-mobile-search-result-item {
  width: 100%; /* Asegura que cada item ocupe el ancho completo */
  padding: 10px;
  border-bottom: 1px solid #eee;
  text-align: center; /* Centra el texto */
}

.page-navbar-mobile-search-no-results {
  text-align: center;
  padding: 10px;
  color: grey;
}


.page-navbar-mobile-search-icon {
  cursor: pointer;
  margin-right: 1rem;
}
