/* src/components/Modelo/ModeloCotizacion.css */

.modelo-cotizacion {
  height: 6rem;
  width: auto;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  margin: 1rem auto 2rem;
}

.modelo-cotizacion-button-container {
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
  font-family: 'Titillium Web', sans-serif;
  width: fit-content;
}

.modelo-cotizacion-button-content {
  background-color: #4A4A49; /* Fondo gris oscuro */
  color: #A3C537; /* Texto verde lima */
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold; /* Texto en negrita */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 3; /* Toma el 75% del espacio */
}

.modelo-cotizacion-button-arrow {
  background-color: #A3C537; /* Fondo verde lima */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  flex-grow: 1; /* Toma el 25% del espacio */
  position: relative;
}

.modelo-cotizacion-button-arrow-icon {
  color: #4A4A49; /* Color gris oscuro para la flecha */
  font-size: 16px;
  position: relative;
}

.modelo-cotizacion-button-container:hover .modelo-cotizacion-button-content {
  background-color: #333; /* Cambio de color al pasar el mouse */
}

@media (max-width: 767px) {
  .modelo-cotizacion {
    height: auto;
    width: auto;

  }
}