.page-alerta-bar {
  width: 100vw; /* Largo total de la página */
  background-color: #aace3b; /* Fondo verde claro */
  border-top: 2px solid #aace3b; /* Borde superior verde */
  border-bottom: 2px solid #aace3b; /* Borde inferior verde */
  text-align: center; /* Centra el contenido horizontalmente */
  padding: 10px 0; /* Espaciado interno */
  position: relative; /* Asegura que se mantenga en el flujo normal de la página */
  z-index: 500; /* Asegura que esté sobre otros elementos */
  overflow: hidden; /* Evita que el contenido se desborde */
}

/* Link dentro de la alerta */
.page-alerta-link {
  color: black; /* Color del texto en estado normal */
  font-size: 1.2rem; /* Tamaño de la fuente */
  text-decoration: none; /* Sin subrayado */
}

.page-alerta-link:hover {
  color: black; /* Mantiene el color negro al hacer hover */
  text-decoration: underline; /* Subraya el texto al hacer hover */
}

/* Efecto de relleno en estado natural */
.page-alerta-bar::before,
.page-alerta-bar::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%; /* Cada pseudo-elemento cubre la mitad del contenedor */
  background-color: #aace3b; /* Color de relleno verde */
  z-index: -1; /* Detrás del contenido */
  transition: background-color 0.3s ease-in-out; /* Transición de color */
}

/* ::before para el relleno de la parte superior */
.page-alerta-bar::before {
  top: 0;
  height: 50%; /* Cubriendo la mitad superior */
}

/* ::after para el relleno de la parte inferior */
.page-alerta-bar::after {
  bottom: 0;
  height: 50%; /* Cubriendo la mitad inferior */
}

/* Efecto hover en desktop */
@media (min-width: 601px) {
  .page-alerta-bar:hover {
    background-color: #90b133; /* Fondo ligeramente más oscuro en hover */
  }

  .page-alerta-bar:hover .page-alerta-link {
    text-decoration: underline; /* Subraya el texto al hacer hover */
  }
}

/* Estilos para móviles */
@media (max-width: 600px) {
  .page-alerta-link {
    font-size: 1rem; /* Tamaño de la fuente */
  }
}
