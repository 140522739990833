.home-linea-slider-mobile-wrapper {
  margin-top: 2rem;
  height: 100%;
}

.home-linea-slider-mobile {
  width: 90vw;
  margin: 0 auto;
}

.home-linea-slider-mobile-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: filter 0.3s ease-in-out, transform 0.2s ease-in-out;
  position: relative;
}

.home-linea-slider-mobile-item.selected .home-linea-slider-mobile-image-wrapper {
  background-color: rgba(185, 247, 70, 0.507);
  transition: background-color 0.3s ease-in-out;
}

.home-linea-slider-mobile-item img {
  filter: grayscale(100%);
  transition: filter 0.3s ease-in-out;
}

.home-linea-slider-mobile-item.selected img {
  filter: grayscale(0%);
  transition: filter 0.3s ease-in-out;
}

.home-linea-slider-mobile-image-wrapper {
  width: 178px;
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
  position: relative; /* Overlay will be positioned relative to this wrapper */
}

.home-linea-slider-mobile-image {
  width: 178px;
  height: 104px;
  object-fit: cover; /* Ensure the image fits the container */
  display: block;
  position: relative; /* Needed for correct z-index behavior */
  z-index: 1; /* Ensures the image stays above the overlay */
}

.home-linea-slider-mobile-image-wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Match the size of the image wrapper */
  height: 100%; /* Match the size of the image wrapper */
  background-color: transparent; /* Remove the overlay color */
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 2; /* Overlay still exists but is invisible */
}

.home-linea-slider-mobile-item.selected .home-linea-slider-mobile-image-wrapper::before {
  opacity: 0; /* Ensure the overlay stays hidden for selected slides */
}


.home-linea-slider-mobile-label {
  margin-top: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}

.home-linea-slider-mobile-arrow {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  padding: 1rem;
}

.home-linea-slider-mobile-arrow-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
