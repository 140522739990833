.home-serie-slider-mobile-background{
  background-image: url(https://en.zoomlion.com/resources/web/img/ipt1_03.jpg);
  background-size: cover;
  background-position: center;
  height: 100%;
}


.home-serie-slider-mobile-wrapper {
  height: 100%;
}

.home-serie-slider-mobile-container {
  width: 90vw;
  margin: 0 auto;
  transform: translateY(10%);
  height: 90%;
}

.home-serie-slider-mobile-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-serie-slider-mobile-button{
  background-color: #333740;
  color: #A2CE64;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  z-index: 4;
}

.home-serie-slider-mobile-button-container {
  position: absolute; /* Changed from fixed to absolute to fit within the wrapper */
  bottom: 10%; /* Adjust the vertical position */
  left: 50%;
  transform: translate(-50%, 0); /* Center horizontally */
  z-index: 4;
  display: flex;
  justify-content: center;
}


.home-serie-slider-mobile-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.home-serie-slider-mobile-label {
  margin-top: 0.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #333740;
  text-align: center;
}

.home-serie-slider-mobile-arrow {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  cursor: pointer;
  top: 60%;
  transform: translateY(-50%);
  padding: 1rem;
}

.home-serie-slider-mobile-arrow-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.2s ease-in-out;
}

.home-serie-slider-mobile-arrow-image:hover {
  transform: scale(1.1);
}
