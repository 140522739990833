/* Main container for mobile */
.product-list-mobile-container {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1rem;
    background-color: white;
    width: 80vw;
    margin-top: 1rem;
  }
  
  /* Select container styles */
  .product-list-mobile-select-container {
    margin-bottom: 1rem;
  }
  
  .product-list-mobile-select-container label {
    display: block;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    font-weight: 400;
  }
  
  .product-list-mobile-select {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
  }
  
  .product-list-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #AACE39;
  }
  