/* Estilos generales para la tarjeta de contacto */
.home-contacto-desktop-card {
  padding: 1rem;
  background-color: transparent;
  border-radius: 0.5rem;
  border: 1px solid white;
  box-shadow: 0 0.25rem 0.625rem rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 37.5rem;
  max-height: 25rem;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
}

/* Botón de la tarjeta */
.home-contacto-desktop-card-button-container {
  margin-top: 1rem;
}

.home-contacto-desktop-card-button {
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
  font-family: 'Titillium Web', sans-serif;
  width: fit-content;
  margin-top: auto;
}

.home-contacto-desktop-card-button-content {
  background-color: #4a4a49;
  color: #a3c537;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 3;
}

.home-contacto-desktop-card-button-arrow {
  background-color: #a3c537;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  flex-grow: 1;
  position: relative;
}

.home-contacto-desktop-card-button-arrow-icon {
  color: #4a4a49;
  font-size: 16px;
  position: relative;
}

/* Cabecera de la tarjeta */
.home-contacto-desktop-card-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: flex-start;
  width: 40rem;
}

/* Título de la tarjeta */
.home-contacto-desktop-card-title {
  font-size: 2.2rem;
  font-weight: 400;
  line-height: 3rem;
  text-align: left;
  color: white;
  width: 100%;
  flex-grow: 1;
}

/* Estilos del selector de país */
.home-contacto-desktop-card-selector {
  background-color: transparent;
  color: white; /* Color de las letras por defecto en blanco */
  padding: 0.625rem;
  border-radius: 0.3125rem;
  border: 1px solid white;
  width: 100%;
  font-size: 1rem;
  font-family: 'Titillium Web', sans-serif; /* Aplicar la fuente Titillium Web */
  text-align: left;
}

/* Estilos de la lista del selector desplegable */
.home-contacto-desktop-card-selector option {
  background-color: white; /* Fondo blanco para las opciones desplegadas */
  color: black; /* Color de las letras al seleccionar la opción */
  font-family: 'Titillium Web', sans-serif; /* Aplicar la fuente Titillium Web */
}

/* Cambiar color de texto seleccionado en el selector */
.home-contacto-desktop-card-selector:focus {
  color: white; /* Cambiar a color negro cuando se selecciona la opción */
  font-family: 'Titillium Web', sans-serif; /* Mantener la fuente Titillium Web */
}

/* Detalles de la oficina */
.home-contacto-desktop-card-details ul {
  list-style: none;
  padding: 0;
  text-align: left;
}

.home-contacto-desktop-card-details li {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  text-align: left;
}

.home-contacto-desktop-card-no-selection {
  color: white;
  font-style: italic;
  text-align: center;
  margin-top: 10px;
}
