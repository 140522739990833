/* Estilos para el submenu */
.navbar-submenu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  display: flex;
  background-color: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

.navbar-submenu-bold {
  font-weight: 600;
}

.navbar-submenu-column-1 {
  width: 25%;
  display: flex;
  flex-direction: column;
}

.navbar-submenu-linea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
  cursor: pointer;
  color: black;
  transition: background-color 0.3s ease;
}

.navbar-submenu-linea:hover,
.navbar-submenu-linea.active { /* Aquí se añade la clase active para la línea seleccionada */
  background-color: #aace39;
}

.navbar-submenu-icon {
  width: 50px;
  height: auto;
  margin-left: 10px;
}

.navbar-submenu-column-2 {
  width: 40%;
  display: flex;
  flex-direction: column;

  padding-left: 20px;
}

.navbar-submenu-serie {
  padding: 13px 10px;
  cursor: pointer;
  color: black;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.navbar-submenu-serie:hover,
.navbar-submenu-serie.active { /* Clase active para la serie seleccionada */
  background-color: #aace39;
  color: black;
}

.navbar-submenu-no-series {
  font-style: italic;
  color: grey;
}

.navbar-submenu-column-3 {
  width: 35%;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}

.navbar-submenu-modelo {
  padding: 10px 10px;
  cursor: pointer;
  color: black;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.navbar-submenu-modelo:hover {
  background-color: #aace39;
  color: black;
}

.navbar-submenu-no-modelos {
  font-style: italic;
  color: grey;
}

/* Estilos para el enlace de Ver más */
.navbar-submenu-ver-mas {
  cursor: pointer;
  color: #aace39;
  font-weight: 600;
  transition: color 0.3s ease, background-color 0.3s ease;
  padding: 10px 10px;

}

.navbar-submenu-ver-mas:hover {
  background-color: lightgrey;
  color: black;
}
