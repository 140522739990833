/* src/components/Modelo/ModeloDestacadoDesktop/ModeloDestacadoDesktopCard.css */
.modelo-destacado-desktop-card {
  background-color: #fff;
  border-radius: 4px;
  margin: 8px;
  padding: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 246px; /* Fijamos el ancho de la tarjeta */
  height: 293px; /* Fijamos la altura de la tarjeta */
  text-align: center;
  overflow: hidden; /* Ocultar el contenido que se desborde */
}

.modelo-destacado-desktop-card-imagen {
  width: 100%;
  height: 150px; /* Controlamos la altura del contenedor de la imagen */
  overflow: hidden; /* Ocultamos cualquier parte que exceda el contenedor */
}

.modelo-destacado-desktop-card-imagen img {
  width: 100%; /* Asegura que la imagen ocupe todo el ancho del contenedor */
  height: 100%; /* Asegura que la imagen ocupe todo el alto del contenedor */
  object-fit: cover; /* Ajusta la imagen para que no se deforme */
  border-radius: 4px;
}

.modelo-destacado-desktop-card-header h3 {
  font-size: 1.5rem;
  font-weight: 900;
  margin: 1rem auto;
  padding-bottom: 4px;
}

.modelo-destacado-desktop-card-header h4 {
  font-size: 12px;
  margin-top: 4px;
  color: #888;
}

/* Estilos del botón */
.modelo-destacado-desktop-card-boton-wrapper {
  margin-top: 1.5rem;
}

.modelo-destacado-desktop-card-boton {
  display: inline-block;
  padding: 10px 20px;
  background-color: #aace3b;
  color: #343A40;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.modelo-destacado-desktop-card-boton:hover {
  background-color: #94b52f; /* Cambia el color al pasar el cursor */
}