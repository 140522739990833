/* src/components/Noticias/NoticiaGrid.css */
.noticia-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr)); /* Cambiado a 300px para que los cards sean más anchos */
  gap: 2rem; /* Aumenta el espacio entre los cards */
  margin-top: 16px;
  justify-content: center; /* Centra horizontalmente las noticias en el grid */
  width: auto;
  height: auto;
}

@media (min-width: 768px) {
  .noticia-grid-container {
    grid-template-columns: repeat(3, 1fr); /* 4 columnas en pantallas grandes */
    justify-content: center; /* Asegura que el grid siempre esté centrado */

  }
}

@media (max-width: 767px) {
  .noticia-grid-container {
    grid-template-columns: 1fr; /* 1 columna en mobile */
  }
}
