/* Ruta: src/components/General/CookieConsent.css */

.cookie-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #333;
    color: #fff;
    padding: 10px;
    text-align: center;
    z-index: 1000;
  }
  
  .cookie-message {
    display: inline-block;
    margin-right: 10px;
  }
  
  .cookie-button {
    background-color: #f1c40f;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    color: #333;
  }
  
  .cookie-link {
    color: #f1c40f;
    text-decoration: underline;
    margin-left: 5px;
  }
  