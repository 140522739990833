.model-modal-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    z-index: 1000;
    border-radius: 8px;
    width: 80%;
    max-width: 500px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  .model-modal-title {
    position: relative;
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #333;
  }
  
  /* Agrega una línea verde debajo del título del modal */
  .model-modal-title::after {
    content: '';
    display: block;
    width: 50px;
    height: 3px;
    background-color: #A3C537; /* Color de la línea */
    margin: 8px auto;
  }
  
  .model-modal-specs-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .model-modal-specs-table th,
  .model-modal-specs-table td {
    padding: 8px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  /* Color de fondo de la primera fila de la tabla */
  .model-modal-specs-table th {
    background-color: #A3C537; /* Color verde en la primera fila */
    color: white; /* Texto blanco en el header */
  }
  
  /* Alternancia de colores en las filas (gris claro y oscuro) */
  .model-modal-specs-table tr:nth-child(even) {
    background-color: #f2f2f2; /* Gris claro */
  }
  
  .model-modal-specs-table tr:nth-child(odd) {
    background-color: #e0e0e0; /* Gris más oscuro */
  }
  
  .model-modal-close-button {
    background-color: #f44336;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
  }
  
  .model-modal-close-button:hover {
    background-color: #d32f2f;
  }
  
  .model-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
    z-index: 999; /* Asegura que esté detrás del modal */
  }
  