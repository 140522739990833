/* src/components/HomeShowcase.css */

.home-showcase-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  overflow: hidden;
}

/* Mobile Styles - screens less than 768px */
@media (max-width: 767px) {

  .home-showcase-container {
    padding: 0px;
    margin: 0 auto;
    gap: 0px;
  }

  .home-showcase-title {
    font-size: 1.75rem;
    color: #4A4A49;
    margin-top: 6rem;
    position: relative;
    text-align: center;

  }

  .home-showcase-title::before {
    content: '';
    width: 50px;
    height: 4px;
    background-color: #A3C537;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);

  }
}
