/* src/components/Noticia/NoticiaUltimas.css */

.noticia-ultimas {
  margin-top: 1rem;
}

.noticia-ultimas-title {
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #393A43;
}

.noticia-ultimas-cards {
  display: flex;
  flex-direction: column; /* Stack cards vertically */
  gap: 1rem;
}

.noticia-ultimas-card {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-decoration: none;
  transition: box-shadow 0.3s ease;
  cursor: pointer;
}

.noticia-ultimas-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.noticia-ultimas-card-img {
  width: 80px; /* Make images square */
  height: 80px;
  object-fit: cover; /* Crop the image to fit */
  border-radius: 8px;
}

.noticia-ultimas-card-content {
  flex: 1;
}

.noticia-ultimas-card-title {
  font-size: 1rem;
  font-weight: 400;
  color: #000;
  margin: 0;
}

.noticia-ultimas-card:hover .noticia-ultimas-card-title {
  text-decoration: underline; /* Optional hover effect for title */
}
