/* src/pages/Productos.css */

.products-page-container {
  overflow-x: hidden;
  background-color: #F3F3F3;
}

.products-page-banner {
  width: 100vw;
  height: 28rem;
  background-color: #f8f9fa;
}

.products-page-content {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.products-page-footer {
  margin-top: 2rem;
}

/* Layout in large screens */
@media (min-width: 768px) {
.products-page-content {
  display: grid;
  grid-template-columns: 20% 80%; /* 30% for ProductList and 70% for ProductGrid */
  gap: 20px;
}
}

/* Center ProductList on mobile */
@media (max-width: 767px) {
.products-page-content {
  align-items: center; /* Center the ProductList component */
}

.products-page-banner {
  height: 17rem;
}
}
