/* src/components/Modelo/ModeloDestacadoMobile/ModeloDestacadoMobileCard.css */
.modelo-destacado-mobile-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 12px auto;
  padding: 12px;
  text-align: center;
  width: 246px; /* La tarjeta ocupa todo el ancho del contenedor */
  height: 293px;
}

.modelo-destacado-mobile-card-imagen {
  width: 100%;
  height: 160px; /* Altura adecuada para móviles */
  overflow: hidden;
}

.modelo-destacado-mobile-card-imagen img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.modelo-destacado-mobile-card-header h3 {
  font-size: 16px;
  margin: 8px 0;
  padding-bottom: 4px;

}

.modelo-destacado-mobile-card-boton-wrapper {
  margin-top: 10px;
}

.modelo-destacado-mobile-card-boton {
  display: inline-block;
  padding: 10px 20px;
  background-color: #aace3b; /* Mismo color de fondo */
  color: #343A40; /* Color de texto igual que en desktop */
  text-decoration: none;
  font-size: 14px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.modelo-destacado-mobile-card-boton:hover {
  background-color: #0056b3;
}
