/* Estilos originales */
.noticias-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
}

.noticias-page-banner {
  width: 100vw;
  height: 24rem;
  background-color: #f8f9fa;
}

@media (max-width: 768px) {
  .noticias-page-banner {
    height: 17rem;
  }
}

/* NUEVOS ESTILOS AGREGADOS */

/* Contenedor general de las noticias */
.noticias-page-container {
  padding: 20px;
  width: 90vw; /* Aumenta el ancho del contenedor para darle más espacio */
  max-width: 1200px; /* Añadir un límite máximo para evitar que sea demasiado ancho */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  overflow: visible; /* Cambia a 'visible' para evitar cortar contenido */
}

/* Estilo del filtro y los botones */
.noticias-page-filtro {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  width: 90vw;
  max-width: 1200px; /* Ajusta también el tamaño del filtro */
}

.noticias-page-ver-mas {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.noticias-page-ver-mas:hover {
  background-color: #45a049;
}

/* Estilos actualizados de los h2 */
.noticias-page-h2,
.noticias-page-h2-resultados {
  font-size: 1.75rem;
  font-weight: 600;
  color: #aace3b;
  text-align: left;
  margin-bottom: 20px;
  width: 90vw; /* Ajustar el ancho de los títulos */
  max-width: 1200px; /* Asegurarse de que no se extienda demasiado */
}

@media (max-width: 768px) {
  .noticias-page-h2 {
    font-size: 1.8rem;
  }

  .noticias-page-h2-resultados {
    font-size: 1.5rem;
  }
}
