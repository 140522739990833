.servicios-page-descripcion {
  display: grid;
  grid-template-columns: 1fr; /* Una columna por defecto en móvil */
  gap: 0rem;
  justify-items: center; /* Centra los ítems horizontalmente */
  margin-top: 2rem;
  margin-bottom: 1rem;
  width: 100%;
}

.servicios-page-banner {
  width: 100vw;
  height: 24rem;
  background-color: #f8f9fa;
}

.service-page-contact {
  height: 300px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service-page-contact-button {
  padding: 1rem 2rem;
  font-size: 1.2rem;
  background-color: #000;
  color: #fff;
  border: none;
  cursor: pointer;
}

/* Efecto de sombra y desplazamiento suave en las tarjetas */
.servicios-page-descripcion-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 400px;
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 0.5rem; /* 8px en rem */
  border: 1px solid #eae9e9;
  transition: box-shadow 0.3s ease, transform 0.3s ease; /* Transición para sombra y desplazamiento */
  opacity: 0; /* Invisible inicialmente */
  transform: translateY(30px); /* Comienza desplazado hacia abajo */
  animation: fadeInUp 1s ease forwards; /* Animación de 0.5 segundos */
}

.servicios-page-descripcion-item:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2); /* Sombra más intensa al hacer hover */
  transform: translateY(-10px); /* Se desplaza hacia arriba al hacer hover */
}

/* Animación para hacer que los elementos aparezcan y se desplacen hacia arriba */
@keyframes fadeInUp {
  to {
    opacity: 1; /* Aparece */
    transform: translateY(0); /* Se mueve a su posición original */
  }
}

/* Efecto de zoom suave en las imágenes */
.servicios-page-img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  margin-bottom: 1rem;
  transition: transform 0.5s ease; /* Transición suave de transformación */
}

.servicios-page-img:hover {
  transform: scale(1.05); /* Hace un zoom del 5% al pasar el mouse */
}

.servicios-page-text {
  margin-top: 0;
  text-align: left;
  opacity: 0.8; /* Opacidad inicial más baja */
  transition: opacity 0.3s ease; /* Transición suave para el desvanecimiento del texto */
}

.servicios-page-descripcion-item:hover .servicios-page-text {
  opacity: 1; /* Texto completamente visible al hacer hover */
}

@media (max-width: 767px) {
  .servicios-page-descripcion-item {
    margin-bottom: 2rem;
  }

  .servicios-page-banner {
    height: 17rem;
  }

  .servicios-page-img {
    filter: none; /* Las imágenes siempre están a color en dispositivos móviles */
  }
}

@media (min-width: 768px) {
  .servicios-page-descripcion {
    grid-template-columns: repeat(3, 1fr); /* Tres columnas en pantallas más grandes */
    gap: 2rem; /* Añade un pequeño espacio entre columnas */
    width: 90%; /* Reduce el ancho del contenedor para que no ocupe todo el ancho */
    margin: 2rem auto; /* Centra el contenedor */
  }
}
