/* src/components/HomeNoticiasSectionMobileCard.css */
.home-noticias-section-mobile-card-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative; /* Necesario para que el botón se pueda posicionar absolutamente */
    border: 1px solid #ddd;
    border-radius: 7px;
    width: 90%;
    height: 25rem;
    margin: 0 auto;
    background-color: #FAF8F8;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Sombra más profunda */
    transition: box-shadow 0.3s ease; /* Suaviza la transición de la sombra al pasar el mouse */
}

.home-noticias-section-mobile-card-container:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3); /* Sombra más intensa al hacer hover */
}

.home-noticias-section-mobile-card-image-container {
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-radius: 5px;
}

.home-noticias-section-mobile-card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Línea antes del título con 20% negro y 80% verde */
/* Línea antes del título con 20% negro y 80% verde */
.home-noticias-section-mobile-card-title::before {
    content: '';
    display: block;
    width: 40px; /* Ajusta el ancho total de la línea */
    height: 3px; /* Ajusta el grosor de la línea */
    background: linear-gradient(to right, black 20%, #aace3b 20%); /* 20% negro y 80% verde */
    margin-bottom: 8px; /* Espacio entre la línea y el título */
}

/* Ajuste del título */
.home-noticias-section-mobile-card-title {
    font-size: 1rem;
    margin-top: 1rem;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 7em; /* Fija la altura del título */
    font-weight: 500;
    padding-left: 1rem;
    text-align: left; /* Alinea el texto del título a la izquierda */
    width: auto; /* Asegura que el título ocupe el ancho completo */

}

/* Asegura que el botón esté en la parte inferior */
.home-noticias-section-mobile-card-button-wrapper {
    position: absolute; /* Fija la posición del botón */
    bottom: 1rem; /* Coloca el botón en la parte inferior */
    left: 50%;
    transform: translateX(-50%); /* Centra el botón horizontalmente */
}

/* Estilos del botón */
.home-noticias-section-mobile-card-button {
    display: inline-block;
    padding: 0.3rem 0.8rem;
    background-color: #aace3b;
    color: #343A40;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 400;
    transition: background-color 0.3s ease;
}

.home-noticias-section-mobile-card-button:hover {
    background-color: #94b52f;
}
