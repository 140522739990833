/* src/components/PageFooter.css */

.page-footer {
  background-color: #ffffff;
  padding: 20px 0;
  border-top: 1px solid #e0e0e0;
  width: 100vw;
}

.page-footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
}

.page-footer-logo {
  height: 50px;
  margin-right: auto;
}

.page-footer-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
}

.page-footer-social-media {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.page-footer-icon-wrapper {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: #aace39;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}

.page-footer-social-icon {
  color: black;
  font-size: 18px;
}

/* Optional: hover effect for icons */
.page-footer-icon-wrapper:hover {
  background-color: #8dbf2d;
}

.page-footer-icon-wrapper a {
  text-decoration: none;
}

/* Footer text */
.page-footer p {
  margin: 20px 0 0;
  font-size: 14px;
  color: #888;
  width: 100%;
  text-align: center;
  font-family: 'Titillium Web', sans-serif;
}

/* Estilo para el enlace de Mirage */
.page-footer-link {
  text-decoration: none;  /* Elimina el subrayado */
  color: #888;            /* Mantiene el color igual al texto */
  font-size: 14px;
  width: 100%;
  text-align: center;
  display: block;
  font-family: 'Titillium Web', sans-serif;
  margin: 10px 0 0;
}

.page-footer-link:hover {
  color: #4A4A49;         /* Color de texto al pasar el mouse */
}


/* Mobile Styles - screens less than 768px */
@media (max-width: 767px) {
  .page-footer {
    background-color: #ffffff;
    padding: 2rem 0;
    border-top: 1px solid #e0e0e0;
    width: 100vw;
    margin-bottom: 2rem;
  }

  .page-footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    margin: 0 auto;
    padding: 10px 20px;
  }

  .page-footer-logo {
    height: 50px;
    margin-bottom: 30px;
    display: block; /* Ensure it behaves like a block element */
    margin-left: auto;
    margin-right: auto; /* This ensures it's centered */
  }

  .page-footer-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .page-footer-social-media {
    display: flex;
    justify-content: center;
  }

  .page-footer-social-media a {
    margin: 0 10px;
    font-size: 24px;
    color: #333;
    text-decoration: none;
  }

  .page-footer p {
    font-size: 1rem;
    color: #888;
    width: 100%;
    text-align: center;
    font-family: 'Titillium Web', sans-serif;
  }
}

/* Tablet Styles - screens between 768px and 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .page-footer {
    background-color: #ffffff;
    padding: 20px 0;
    border-top: 1px solid #e0e0e0;
    width: 100vw;
  }

  .page-footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 90%;
    margin: 0 auto;
    padding: 10px 20px;
  }

  .page-footer-logo {
    height: 50px;
    margin-right: auto;
  }

  .page-footer-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
  }

  .page-footer-social-media {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }

  .page-footer-social-media a {
    margin: 0 10px;
    font-size: 24px;
    color: #333;
    text-decoration: none;
  }

  .page-footer p {
    margin: 20px 0 0;
    font-size: 14px;
    color: #888;
    width: 100%;
    text-align: center;
    font-family: 'Titillium Web', sans-serif;
  }
}
