/* src/components/HomeNoticiasSectionMobile.css */
.home-noticias-section-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%; /* 100% de la altura de la pantalla */
    background-color: #f8f9fa;
    overflow: hidden;
  }
  
  .home-noticias-section-mobile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 90%; /* Mantiene un ancho máximo del contenido */
    padding: 2rem;
  }
  
  .home-noticias-section-mobile-title {
    font-size: 2rem;
    color: #393A43;
    margin-bottom: 4rem;
    text-align: center; /* Centra el texto */
  }
  
  /* Centra el slider horizontalmente */
  .home-noticias-section-mobile-slider-wrapper {
    margin: 0 auto; /* Centra horizontalmente */
    width: 100%;
    max-width: 360px; /* Limita el ancho del slider */
  }
  
  /* Botón Ver Más */
  .home-noticias-section-mobile-ver-mas-wrapper {
    display: flex;
    justify-content: center; /* Centra horizontalmente */
    margin-top: 8rem;
  }
  
  .home-noticias-section-mobile-ver-mas {
    display: inline-block;
    width: 140px;
    height: 42px;
    background-color: #393A43;
    color: #AACE3B;
    text-align: center;
    line-height: 42px; /* Asegura que el texto esté centrado verticalmente */
    font-size: 1rem;
    font-weight: bold;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .home-noticias-section-mobile-ver-mas:hover {
    background-color: #2f2f36; /* Cambio de color al hacer hover */
  }
  
  .home-noticias-section-mobile-slider-wrapper .slick-dots {
    bottom: -3rem; /* Ajusta el valor para mover los dots más abajo */
}


  .home-noticias-section-mobile-slider-wrapper .slick-dots li button:before {
    color: black;
    opacity: 1; /* Asegura que los dots no se vean desvanecidos */
}

.home-noticias-section-mobile-slider-wrapper .slick-dots li.slick-active button:before {
  color: #AACE3B; /* Cambia el color de los dots */
    opacity: 1; /* Asegura que el dot activo no se desvanezca */
}