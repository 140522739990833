.noticia-social {
  text-align: left;
}

.noticia-social-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #393A43;
  margin-bottom: 1rem;
}

.noticia-social-icons {
  display: flex;
  gap: 1rem; /* Add spacing between icons */
}

.noticia-social-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f5f5f5;
  transition: background-color 0.3s ease;
}

.noticia-social-icon-wrapper:hover {
  background-color: #007bff; /* Hover effect color */
}

.noticia-social-icon {
  font-size: 1.2rem;
  color: #444;
  transition: color 0.3s ease;
}

.noticia-social-icon-wrapper:hover .noticia-social-icon {
  color: white; /* Icon color on hover */
}
