.contact-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
}

.contact-page-information {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: auto;
  overflow-y: hidden;
  gap: 4rem;
}

.contact-page-banner {
  width: 100vw;
  height: 24rem;
  background-color: #f8f9fa;
}

.contact-page-content {
  display: flex;
  width: 80%;
  margin-left: 10rem;
  margin-right: 10rem;
  padding: 1rem;
  justify-content: space-between;
  margin-top: 20px;
  flex-wrap: wrap;  
}

.contact-page-card {
  width: 65rem;
  display: flex;
  padding: 1rem;
}

.contact-page-map {
  width: 65rem;
  display: flex;
  padding: 1rem;
}

.contact-page-footer {
  width: 100vw;
  padding: 20px;
  background-color: white;
  text-align: center;
}

/* Media query para dispositivos móviles */
@media (max-width: 768px) {
  .contact-page-information {
    flex-direction: column; /* Cambia a una columna en pantallas móviles */
    height: auto; /* Ajusta la altura para que se adapte al contenido */
    gap: 1rem; /* Reduce el espacio entre las secciones */
  }

  .contact-page-card,
  .contact-page-map
  {
    width: 90%; /* Asegura que cada sección ocupe el 100% del ancho en móvil */
    padding: 0.5rem; /* Ajusta el padding para pantallas pequeñas */
  }

  .contact-page-banner {
    height: 17rem;
  }

  .contact-page-content {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0.5rem;
    gap: 5px;
    margin-bottom: 2rem;
    justify-content: center; /* Centra el contenido horizontalmente */
    align-items: center; /* Centra el contenido verticalmente */
  }


  .contact-page-footer {
    width: 100vw;
    padding: 0px;
    background-color: white;
    text-align: center;
  }
}

/* Estilos para los grupos de formulario (label y select) */
.contact-form-group {
  margin-bottom: 1rem; /* Ajuste del espacio entre grupos */
}

.contact-form-group label {
  display: block;
  margin-bottom: 0.5rem; /* Espacio entre label y select */
  font-weight: 500;
  font-size: 1rem;
  color: #333;
}

/* Estilos para los selectores del formulario ContactForm */
.contact-form-group select {
  width: 100%;
  padding: 0.5rem; /* Mismo tamaño de padding que los campos de texto */
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.contact-form-group select:focus {
  border-color: #007bff;
  outline: none;
}

.contact-form-group option {
  padding: 0.5rem;
  font-size: 1rem;
}

/* Ajustes de los selectores en dispositivos móviles */
@media (max-width: 768px) {
  .contact-form-group select {
    width: 100%;
    padding: 0.4rem;
    font-size: 0.9rem;
  }

  .contact-form-group label {
    margin-bottom: 0.4rem; /* Ajuste del espacio en pantallas móviles */
  }
}
