/* src/components/Modelo/ModeloFicha.css */

.modelo-ficha {
    height: 6rem;
    width: auto;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    margin: 1rem auto 2rem;
  }
  
  .modelo-ficha-button-container {
    display: flex;
    cursor: pointer;
    border-radius: 5px;
    /* Elimina overflow: hidden para evitar que corte los bordes */
    font-family: 'Titillium Web', sans-serif;
    width: fit-content;
  }
  
  .modelo-ficha-button-content {
    background-color: transparent; /* Sin fondo */
    color: #A3C537; /* Color del texto y del borde */
    border: 1px solid #A3C537; /* Borde del color actual */
    border-radius: 5px;
    font-weight: 400;
    padding: 12px 24px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 3; /* Toma el 75% del espacio */
  }
  
  .modelo-ficha-button-arrow {
    background-color: #A3C537; /* Fondo verde lima */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    flex-grow: 1; /* Toma el 25% del espacio */
    /* Asegura que no afecte al borde del contenido */
  }
  
  .modelo-ficha-button-arrow-icon {
    color: #4A4A49; /* Color gris oscuro para la flecha */
    font-size: 16px;
  }
  
  .modelo-ficha-button-container:hover .modelo-ficha-button-content {
    background-color: #A3C537; /* Fondo verde lima */
    color: black; /* Color del texto y del borde */
  }
  
  @media (max-width: 767px) {
    .modelo-ficha {
        height: auto;
        width: auto;
      }
  }