/* src/components/FullPageWrapper.css */

#fullpage .section {
    text-align: center;
    font-size: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    margin: 0;
    padding: 0; /* Remove padding to prevent overflow */
  }
  
  .section1 {
    background-color: white;
  }
  
  .section2 {
    background-color: white;
  }
  
  .section3 {
    background-color: white;
  }
  
  .section4 {
    background-color: white;
  }
  
  .section5 {
    background-color: yellow;
  }
  