.home-showcase-desktop-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  overflow: hidden;

}

.home-showcase-desktop-lineas-wrapper {
  height: 14rem;  /* Fixed height */
/*  border: 1px black solid; */
}

.home-showcase-desktop-series-wrapper {
  flex-grow: 1;  /* Occupies the remaining space */
/*border: 1px pink solid;*/
}

/* Removes default content from slick arrows */
.slick-prev:before, .slick-next:before {
  content: '' !important;  /* Removes the default arrows */
}
