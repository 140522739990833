/* Contenedor principal de la sección en versión móvil */
.home-contacto-section-mobile-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* Mapa en la parte superior para la versión móvil */
.home-contacto-section-mobile-mapa {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  width: 100%;
  max-height: 30%;
  overflow: hidden;
  margin-top: 2rem;
}

/* Contenedor de la tarjeta en la parte inferior para la versión móvil */
.home-contacto-section-mobile-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Centramos horizontalmente el contenido */
  background-color: transparent;
  width: 100%; /* Aseguramos que ocupe todo el ancho */
  height: 50%; /* Ocupa la otra mitad de la pantalla */
  box-sizing: border-box; /* Incluimos el borde en el tamaño total */
  margin-bottom: 2rem;
}

/* Cargando... para la versión móvil */
.home-contacto-section-mobile-cargando {
  text-align: center;
  font-size: 1.2rem;
  color: white;
  padding: 1rem;
}
