/* src/components/Noticias/NoticiaFiltro.css */
.noticias-page-filtro {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .noticias-page-filtro-buscar {
    padding: 8px;
    font-size: 1rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 16rem;
  }
  
  .noticias-page-filtro-ordenar {
    padding: 8px;
    font-size: 1rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 12rem;
  }
  