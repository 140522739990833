.noticia-page-content-wrapper {
  display: flex;
  justify-content: space-between;
  max-width: 80vw;
  margin: 5rem auto;
  align-items: flex-start; /* Align secondary to the start of the image */
}

.noticia-page-content {
  flex: 2;
  text-align: justify;
}

.noticia-page-secondary {
  flex: 1;
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

/* Center image and adjust bajada width */
.noticia-page-imagen {
  display: block; /* Ensures the image behaves like a block-level element */
  max-width: 100%; /* Makes the image responsive */
  height: auto;
  border-radius: 8px;
  margin: 0 auto 20px; /* Center the image horizontally and add spacing below */
}

.noticia-page-bajada {
  font-size: 1.5rem;
  color: #666;
  width: 100%; /* Stretch across the entire wrapper width */
  margin: 1rem 0;
  text-align: left; /* Align text to the left */
}

.noticia-page-contenido {
  text-align: justify;
  line-height: 1.6;
  font-size: 1.1rem;
  color: #444;
  max-width: 60vw;
  margin: 0 auto;
}

.noticia-page-secondary {
  flex: 1;
  margin-left: 3rem; /* Increased from 2rem to 3rem */
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-self: flex-start; /* Align secondary column to the top of the image */
  padding-top: 0.5rem;
}


/* Responsive adjustments */
@media (max-width: 768px) {
  .noticia-page-content-wrapper {
    flex-direction: column; /* Stack content and secondary on smaller screens */
  }

  .noticia-page-secondary {
    margin-left: 0; /* Remove left margin in stacked layout */
    margin-top: 2rem; /* Add spacing between sections */
  }

  .noticia-page-titulo{
    font-size: 1.5rem;
  }

  .noticia-page-contenido {
    max-width: 80vw;
  }

  .noticia-page-bajada {
    font-size: 1rem;
    width: 100%; /* Stretch across the full width of the wrapper */
    margin: 1rem 0;
  }

  .noticia-page-imagen {
    max-width: 100%;
    margin: 0 auto 20px; /* Center image on small screens */
  }
}
