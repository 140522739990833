.modelo-header-mobile-banner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    background-color: #f8f9fa;
    overflow: hidden;
}

/* Clase para la altura estándar cuando hay descripción */
.modelo-header-full-height {
    height: 100vh;
}

/* Clase para altura reducida cuando NO hay descripción */
.modelo-header-reduced-height {
    height: auto;
}

.modelo-header-mobile-banner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80%; /* Ocupa el 65% superior del banner */
    background-size: cover;
    background-position: center;
    z-index: 1; /* Mantener el fondo detrás del contenido */
    background-image: var(--background-image); /* Utiliza una variable de CSS para la imagen */
}

.modelo-header-mobile-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    margin-top: 8rem;
    z-index: 2;
}


.modelo-header-mobile-texto {
    text-align: center;
    padding: 10px;
}

.modelo-header-mobile-title {
    color: #303337;
    font-size: 3rem;
    font-weight: 900;
    margin-bottom: 0.5rem;
    margin-top: 0rem;
    text-align: left; /* Aligns the text to the left */
    
/*  border: 1px red dotted;*/
}

.modelo-header-mobile-serie {
    font-size: 1.2rem;
    color: black;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: left; /* Aligns the text to the left */
    margin-top: 0px;
    
 /* border: 1px yellow dotted;*/
}

/* Asegura que el enlace no tenga subrayado y mantenga su estilo al hacer hover */
.modelo-header-mobile-serie a {
    text-decoration: none;  /* Sin subrayado */
    color: inherit;  /* Mantiene el color heredado */
}


.modelo-header-mobile-description {
    font-size: 1rem;
    color: black;
    margin-top: 1rem;
    padding-top: 1rem;
    text-align: left; /* Aligns the text to the left */
}

.modelo-header-mobile-image-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modelo-header-mobile-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

.modelo-header-mobile-ghost {
    position: absolute;
    top: 10%; /* Center it vertically within its parent */
    left: 50%; /* Center it horizontally within its parent */
    transform: translate(-50%, -50%) skew(-10deg); /* Center it using translate and apply skew */
    font-size: 4rem;
    font-weight: 900;
    font-stretch: expanded; /* Makes the text fatter */
    color: rgba(0, 0, 0, 0.15);
    white-space: nowrap;
    z-index: 100;
    pointer-events: none;
}

