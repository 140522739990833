/* src/components/Modelo/ModeloDestacadoMobile/ModeloDestacadoMobile.css */
.modelo-destacado-mobile-section {
  background-color: #f8f9fa;
  width: 100vw;
  height: 30rem;
  display: flex;
  justify-content: center; /* Centra la sección horizontalmente */
}

.modelo-destacado-mobile-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra el contenido del contenedor */
  width: 100%;
}

.modelo-destacado-mobile-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 16px;
  text-align: center;
}

/* Wrapper para el slider */
.modelo-destacado-mobile-slider-wrapper {
  width: 100%;
  max-width: 400px; /* Limita el ancho máximo del slider */
  margin: 0 auto; /* Asegura que esté centrado horizontalmente */
  display: block; /* No modificamos la estructura del slider, solo centramos */
}
