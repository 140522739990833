/* Contenedor del filtro */
.lead-explorer-filter-container {
  margin-bottom: 15px;
  text-align: center;
}

.lead-explorer-filter-container label {
  margin-right: 10px;
  font-weight: bold;
}

.lead-explorer-filter-container select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

/* Estilos de la tabla */
.lead-explorer-table {
  margin: 0 auto;
  width: 90%;
  border-collapse: collapse;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.lead-explorer-table th,
.lead-explorer-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

.lead-explorer-table-header {
  background-color: #f4f4f9;
  font-weight: bold;
}

/* Botón general */
.lead-explorer-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.lead-explorer-button:hover {
  background-color: #218838;
}

/* Botón "Ver más" */
.lead-explorer-button-view-more {
  background-color: #4285f4;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.lead-explorer-button-view-more:hover {
  background-color: #357ae8;
}

/* 
  ======================
  1) Estados vacíos 
     (sin estilo “pill” en el td)
  ======================
*/
.lead-explorer-status-new {}
.lead-explorer-status-in-progress {}
.lead-explorer-status-attended {}

/*
  ======================
  2) El <select> hereda
     el color según la clase del padre
  ======================
*/
.lead-explorer-status-new .lead-explorer-status-dropdown {
  background-color: #555555; /* Gris oscuro */
  color: #ffffff;
}

.lead-explorer-status-in-progress .lead-explorer-status-dropdown {
  background-color: #fbc02d; /* Amarillo */
  color: #000000;
}

.lead-explorer-status-attended .lead-explorer-status-dropdown {

  background-color: #388e3c; /* Verde */
  color: #ffffff;
}

/*
  ======================
  3) Ajustes generales 
     para que el <select> 
     luzca como una etiqueta redondeada
  ======================
*/
.lead-explorer-status-dropdown {
  border: none; 
  border-radius: 9999px;  /* "Píldora" */
  padding: 8px 16px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
  cursor: pointer;
  /* Quita la flecha nativa si quieres:
     -webkit-appearance: none; 
     -moz-appearance: none;
     appearance: none;
  */
}

/* Modal */
.lead-explorer-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.lead-explorer-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  width: 400px;
  text-align: center;
}
