/* ========================= */
/* 1. ESTILOS GENERALES      */
/* ========================= */

.landing-peru-page {
  font-family: 'Titillium Web', sans-serif;
}

.landing-peru-contact-button:hover {
  background-color: #444;
}

.landing-peru-title {
  font-weight: bold;
  color: #333; /* Asegura un color consistente */
}

.landing-peru-subtitle {
  color: #666;
}

.landing-peru-contact-button {
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.landing-peru-title span {
  transition: color 0.3s ease; /* Transición suave para el cambio de color */
}

.landing-peru-title span:hover {
  color: #accb41; /* Cambia el color al pasar el mouse */
}


/* ========================= */
/* 2. DEFINICIÓN DE KEYFRAMES */
/* ========================= */

/* Mobile Vertical (hasta 480px) */
@keyframes slide-in-out-mobile-vertical {
  0% {
    opacity: 0;
    transform: translateX(120%);
  }
  25% {
    opacity: 1;
    transform: translateX(10%);
  }
  75% {
    opacity: 1;
    transform: translateX(10%);
  }
  100% {
    opacity: 0;
    transform: translateX(120%);
  }
}

/* Mobile Horizontal (481px a 768px) */
@keyframes slide-in-out-mobile-horizontal {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  25% {
    opacity: 1;
    transform: translateX(20%);
  }
  75% {
    opacity: 1;
    transform: translateX(20%);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

/* Desktop (769px a 1199px) */
@keyframes slide-in-out-desktop {
  0% {
    opacity: 0;
    transform: translateX(150%);
  }
  25% {
    opacity: 1;
    transform: translateX(20%);
  }
  75% {
    opacity: 1;
    transform: translateX(20%);
  }
  100% {
    opacity: 0;
    transform: translateX(-10%);
  }
}

/* Large Desktop (1200px en adelante) */
@keyframes slide-in-out-large-desktop {
  0% {
    opacity: 0;
    transform: translateX(150%);
  }
  25% {
    opacity: 1;
    transform: translateX(15%);
  }
  75% {
    opacity: 1;
    transform: translateX(15%);
  }
  100% {
    opacity: 0;
    transform: translateX(-10%);
  }
}

/* ========================= */
/* 3. BREAKPOINTS CON ANIMACIONES */
/* ========================= */

/* Mobile Vertical (hasta 480px) */
@media (max-width: 480px) {
  .landing-peru-page-img {
    animation: slide-in-out-mobile-vertical 3s ease-in-out infinite;
  }

  .landing-peru-hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 2em;
    padding: 2em;
    height: auto;

  }

  /* Oculta el HeroCanvas en móviles si está interfiriendo */
  .landing-peru-hero > .HeroCanvas {
    display: none;
  }

  .landing-peru-text-section {
    order: 1; /* Fuerza al texto a aparecer primero */
    width: 100%; /* Ocupa toda la altura del contenedor padre */
  }

  .landing-peru-title {
    font-size: 1.8em;
    margin-bottom: 1em; /* Espacio debajo del título */
    margin-top: 2em;
    order: 1; /* Título primero */
  }

  .landing-peru-image-section {
    width: 100%;
    margin-bottom: 1em; /* Espacio debajo de la imagen */
    order: 2; /* Imagen segundo */
  }

.landing-peru-page-img {
  width: 80%; /* Ajusta el ancho de la imagen */
  animation: none;
}

.landing-peru-subtitle {
  font-size: 1.3em;
  margin-bottom: 1em; /* Espacio debajo del subtítulo */
  order: 3; /* Subtítulo tercero */
}

.landing-peru-contact-button {
  padding: 0.8em 1.8em;
  font-size: 1.2em;
  order: 4; /* Botón cuarto */
}

}

/* Mobile Horizontal (481px a 768px) */
@media (min-width: 481px) and (max-width: 768px) {

  .landing-peru-hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 2em;
    padding: 4em;
    height: auto;
  }

  /* Oculta el HeroCanvas en móviles si está interfiriendo */
  .landing-peru-hero > .HeroCanvas {
    display: none;
  }

  .landing-peru-text-section {
    order: 1; /* Fuerza al texto a aparecer primero */
    width: 100%; /* Ocupa toda la altura del contenedor padre */
  }

  .landing-peru-title {
    font-size: 2.4em;
    line-height: 1em;
    margin-bottom: 1em; /* Espacio debajo del título */
    margin-top: 2em;
    order: 1; /* Título primero */
  }

  .landing-peru-subtitle {
    font-size: 1.4em;
    margin-bottom: 1em; /* Espacio debajo del subtítulo */
    order: 3; /* Subtítulo tercero */
  }

  .landing-peru-image-section {
    width: 100%;
    margin-bottom: 1em; /* Espacio debajo de la imagen */
    order: 2; /* Imagen segundo */
  }

.landing-peru-page-img {
  width: 70%; /* Ajusta el ancho de la imagen */
  animation: none;
}

.landing-peru-contact-button {
  margin-top: 1em;
  padding: 0.7em 1.4em;
  font-size: 1.2em;
  order: 4; /* Botón cuarto */
}

}

/* Desktop (769px a 1199px) */
@media (min-width: 769px) and (max-width: 1199px) {
  
  /* Hero section */
.landing-peru-hero {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100vh;
  width: auto;
  padding: 1em 0em 0em 2.5em;
  text-align: center;
  position: relative;
  overflow: hidden;
  background-color: white;
  justify-content: flex-start;
  text-align: left;
  gap: 3rem;
}

.landing-peru-text-section {
  max-width: 30em;
  z-index: 10;
}

.landing-peru-title {
  font-size: 2.8rem;
  font-weight: bold;
  margin-bottom: 1rem;
  line-height: 1.2;
  color: #333; /* Asegura un color consistente */
  z-index: 100;
  position: relative;
}

.landing-peru-subtitle {
  font-size: 1.4rem;
  color: #666;
  margin-bottom: 1.5rem;
  z-index: 200;
  position: relative;
}

.landing-peru-contact-button {
  padding: 1rem 2rem;
  margin-top: 2rem;
  font-size: 1.2rem;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.landing-peru-image-section {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 40%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  z-index: 1;
}

.landing-peru-page-img {
  width: 80%;
  height: auto;
  position: absolute;
  animation: slide-in-out-desktop 4s ease-in-out infinite; /* Animación por defecto para Desktop */
}

}

/* Large Desktop (1200px en adelante) */
@media (min-width: 1200px) {

  /* Hero section */
.landing-peru-hero {
  display: flex;
  justify-content: flex-start;
  text-align: left;
  gap: 3rem;
  align-items: center;
  height: 100vh;
  padding: 0em 5em 0em 5em;
  margin: auto 0;
  position: relative;
  overflow: hidden;
  background-color: white;
}

.landing-peru-text-section {
  max-width: 50em;
  z-index: 1;
}

.landing-peru-title {
  font-size: 3.5em;
  text-align: left; /* Alinea el texto a la izquierda */
  font-weight: bold;
  margin-bottom: 1rem;
  line-height: 1.2;
  color: #333; /* Asegura un color consistente */
}

.landing-peru-subtitle {
  font-size: 1.8em;
  text-align: left;
  color: #666;
  margin-bottom: 1.5rem;
}

.landing-peru-contact-button {
  padding: 1rem 2rem;
  margin-top: 2rem;
  font-size: 1.2rem;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.landing-peru-image-section {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

.landing-peru-page-img {
  width: 80%;
  height: auto;
  position: absolute;
  animation: slide-in-out-large-desktop 4s ease-in-out infinite;
}

}